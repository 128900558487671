import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";

const EditEventFestival = () => {
  const { state } = useLocation();
  const { eventData } = state || {}; // eventData might be undefined if not passed
  console.warn("event data", eventData);
  const { updateEventFastival } = useApiAxios();
  const { userData } = useContext(Context);
  console.warn("context data", userData);
  const navigate = useNavigate();

  const formatDate = (date) => {
    if (!date) return "";
    const localDate = new Date(date);
    return localDate.toISOString().split("T")[0]; // Ensures correct format
  };
  
  const [formData, setFormData] = useState({
    eventName: eventData?.eventName || "",
    startDate: formatDate(eventData?.startDate),
    endDate: formatDate(eventData?.endDate),
    image: eventData?.image || "",
    type: eventData?.type || "",
    paymentType: eventData?.paymentType || "",
    totalstall: eventData?.totalstall || "",
    description: eventData?.description || "",
    amount: eventData?.amount || "",
    address: eventData?.address || "",  

  });
  

  // Preview state for the image. Initially, if an image exists, show it using the env endpoint.
  const [preview, setPreview] = useState(
    eventData?.image
      ? `${process.env.REACT_APP_IMG_URL}/${eventData.image}`
      : null
  );

  // Handle regular form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "paymentType" && value === "free") {
      setFormData((prev) => ({
        ...prev,
        amount: "", // Reset amount if switching to free
      }));
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      setPreview(URL.createObjectURL(file)); // Preview the image
      setFormData((prev) => ({
        ...prev,
        image: file, // Ensure only the file is stored
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const submissionData = new FormData();
    submissionData.append("id", eventData._id);
    submissionData.append("eventName", formData.eventName);
    submissionData.append("startDate", new Date(formData.startDate).toISOString());
    submissionData.append("endDate", new Date(formData.endDate).toISOString());
    submissionData.append("type", formData.type);
    submissionData.append("paymentType", formData.paymentType);
    submissionData.append("description", formData.description);
    submissionData.append("marketOwnerId", userData?._id);
    submissionData.append("address", formData.address);
  
    if (formData.paymentType === "paid") {
      submissionData.append("amount", formData.amount);
    }
  
    try {
      // If a new image is selected, send it
      if (formData.image instanceof File || formData.image instanceof Blob) {
        submissionData.append("image", formData.image, formData.image.name);
      } 
      // If the user has not changed the image, fetch and send the existing image as a Blob
      else if (eventData.image) {
        const response = await fetch(`${process.env.REACT_APP_IMG_URL}/${eventData.image}`);
        const blob = await response.blob();
        const file = new File([blob], eventData.image.split("/").pop(), { type: blob.type });
        submissionData.append("image", file);
      }
  
      const response = await updateEventFastival(submissionData);
      console.log("Event updated successfully:", response.data);
      navigate("/event-festival");
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };
  
  
  
  
  
  
  

  if (!eventData) {
    return <div>No event data available.</div>;
  }
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  return (
    <div>
      <div className="">
        <div className="panel-header">
          <div className="page-inner pb-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="pb-2 fw-bold">Edit Events & Festival</h2>
                <ul className="breadcrumbs">
                  <li className="nav-home">
                    <Link to="/">
                      <i className="flaticon-home" />
                    </Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <Link to="/event-festival">Edit Events & Festival</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h2 style={{ marginLeft: "25px" }}>
          {eventData.eventName || "Untitled"}
        </h2>

        <div
          style={{ maxWidth: "500px", marginLeft: "25px", marginRight: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Event Name</label>
              <input
                type="text"
                name="eventName"
                value={formData.eventName}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>Start Date</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                className="form-control"
                min={today} // Prevent selecting past dates

              />
            </div>

            <div className="form-group">
              <label>End Date</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                className="form-control"
                min={formData.startDate || today} // Ensure end date is after start date

              />
            </div>
            <div className="form-group">
            <label>Address</label>  {/* <-- New Address Field */}
            <input type="text" name="address" value={formData.address} onChange={handleChange} className="form-control" placeholder="Enter event address" />
          </div>
            {/* Updated image input as file */}
            <div className="form-group">
              <label>Upload Image</label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            {/* Image Preview */}
            {preview && (
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={preview}
                  alt={formData.eventName}
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    borderRadius: "5px"
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <label>Type</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">Select Type</option>
                <option value="festival">Festival</option>
                <option value="event">Event</option>
              </select>
            </div>

            <div className="form-group">
              <label>Payment Type</label>
              <select
                name="paymentType"
                value={formData.paymentType}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">Select Payment Type</option>
                <option value="free">Free</option>
                <option value="paid">Paid</option>
              </select>
            </div>

            {formData.paymentType === "paid" && (
  <div className="form-group">
    <label>Amount</label>
    <input
      type="number"
      name="amount"
      value={formData.amount}
      onChange={handleChange}
      className="form-control"
      placeholder="Enter amount"
      min="1"
    />
    {formData.paymentType === "paid" && (!formData.amount || formData.amount <= 0) && (
      <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
        Amount is required for paid events.
      </p>
    )}
  </div>
)}

            <div className="form-group">
              <label>Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <button type="submit" className="btn btn-primary mt-3">
              Update Event
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEventFestival;
