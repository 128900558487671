import { Outlet } from "react-router-dom";
import { useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import Footer from "../footer/Footer";
import "./backend.css"; // Import the CSS file

const Backend = () => {
	const [sideBarEnable, setSideBarEnable] = useState(true);
	const [showMobileSidebar, setShowMobileSidebar] = useState(false);

	return (
		<div data-sidebar="light" data-layout-mode="light" className={`${sideBarEnable ? "sidebar-enable" : "vertical-collapsed"} ${showMobileSidebar ? "nav_open" : ""}`}>
			<div className="wrapper" id="layout-wrapper">
				<Header 
					setSideBarEnable={setSideBarEnable} 
					showMobileSidebar={showMobileSidebar}
					setShowMobileSidebar={setShowMobileSidebar}
				/>
				<SideBar 
					isCollapsed={!sideBarEnable}
					showMobileSidebar={showMobileSidebar}
					setShowMobileSidebar={setShowMobileSidebar}
				/>
				<div className={`container-fluid ${sideBarEnable ? "expanded" : "collapsed"}`}>
					<div className={`wrapper`}>
				<div className={`main-panel ${sideBarEnable ? "expanded" : "collapsed"}`}>
					<div className="content">
						<Outlet />
					</div>
					<Footer />
				</div>
				</div>
			</div>
			</div>
		</div>



	);
};

export default Backend;
