import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

const VendorEmailChange = () => {
  const { changeEmailApi, changeEmailOTP } = useApiAxios();
  const { userData } = useContext(Context);
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [Email] = useState(userData.vendor?.email || ""); // Prefill old email

  useEffect(() => {
    document.title = "Settings - Eventease360";
  }, []);

  const initialValues = {
    newEmail: "",
  };

  const validationSchema = Yup.object({
    newEmail: Yup.string()
      .email("Invalid email format")
      .required("New email is required"),
  });

  const handleSendOtp = async () => {
    try {
      const response = await changeEmailApi({ Email: Email });

      if (!response.error) {
        setOtpSent(true);
      } else {
        alert(response.data.message || "Failed to send OTP.");
      }
    } catch (error) {
      alert("Error sending OTP.");
    }
  };

  const handleVerifyOtpAndUpdateEmail = async (values, { setSubmitting }) => {
    try {
      const response = await changeEmailOTP({
        Email, 
        otp,
        newEmail: values.newEmail,
      });

      if (!response.error) {
        navigate("/");
      } else {
        alert(response.data.message || "Failed to verify OTP or update email.");
      }
    } catch (error) {
      alert(error.data.message || "Failed to verify OTP or update email.");

    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      
      <div className="panel-header">
              <div className="page-inner pb-5">
                <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                  <div>
                    <h2 className="pb-2 fw-bold">Update Vendor Email</h2>
                    <ul className="breadcrumbs">
                      <li className="nav-home">
                        <Link to="/">
                          <i className="flaticon-home" />
                        </Link>
                      </li>
                      <li className="separator">
                        <i className="flaticon-right-arrow" />
                      </li>
                      <li className="nav-item">
                        <Link to="/emailChangeSetting">Transaction Logs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

      <div className="page-inner">
        <div className="col-md-10 col-xl-8 mx-auto">
          <div className="card shadow-sm border-0">
            <div className="card-header bg-gradient text-white">
              <h5 className="mb-0">Business: {userData.vendor?.businessName}</h5>
            </div>

            <div className="card-body p-4">
              <h5 className="fw-bold text-primary">Email Update Process</h5>
              <p className="small text-muted">Follow the steps to update your email address.</p>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleVerifyOtpAndUpdateEmail}
              >
                {({ values, isSubmitting }) => (
                  <Form>
                    {/* Old Email Section */}
                    <div className="mb-4">
                      <label htmlFor="Email" className="fw-semibold mb-1">Current Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        value={Email}
                        disabled
                      />
                    </div>

                    {/* OTP Send Button */}
                    {!otpSent && (
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={handleSendOtp}
                        >
                          Send OTP to Current Email
                        </button>
                      </div>
                    )}

                    {/* New Email & OTP Sections (shown after OTP sent) */}
                    {otpSent && (
                      <>
                        <div className="mt-4">
                          <label htmlFor="newEmail" className="fw-semibold mb-1">New Email Address</label>
                          <Field
                            type="email"
                            name="newEmail"
                            className="form-control"
                            placeholder="Enter new email"
                          />
                          <ErrorMessage name="newEmail" component="div" className="text-danger small mt-1" />
                        </div>

                        <div className="mt-4">
                          <label htmlFor="otp" className="fw-semibold mb-1">Enter OTP</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>

                        <div className="d-flex justify-content-end mt-4">
                          <button
                            type="submit"
                            className={`btn ${otp ? "btn-primary" : "btn-secondary"}`}
                            disabled={isSubmitting || !otp}
                          >
                            {isSubmitting ? "Updating..." : "Verify OTP & Update Email"}
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorEmailChange;
