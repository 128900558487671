import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";

const SubCategory = () => {
	document.title = "Eventease360 | SubCategory";
	const { getAllSubCategoryApi, createSubCategoryApi, searchCategoryApi } = useApiAxios();
	const [openCreateModalState, setCreateModalState] = useState(false);
	const [adminData, setAdminData] = useState([]);
	const [currentCategories, setCurrentCategories] = useState([]);
	const [searchQuery, setSearchQuery] = useState(""); // State for search query
	const [categoriesPerPage, setCategoriesPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const navigate = useNavigate();

	const [total, setTotal] = useState(0);
	const categoriesPerPageOptions = [10, 20, 50, 100];

	const openCreateModal = async () => {
		setCreateModalState(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const fetchCategories = async (page, limit) => {
		const res = await getAllSubCategoryApi(page + 1, limit);
		if (!res.error) {
			setAdminData(res.data.data);
			setCurrentCategories(res.data.data);
			setTotal(res.data.total);
		}
	};

	useEffect(() => {
		fetchCategories(currentPage, categoriesPerPage);
	}, [currentPage, categoriesPerPage]);

	const handleCloseCategoryModal = () => {
		setCreateModalState(false);
		formik.resetForm();

		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const formik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Name is required"),
		}),
		onSubmit: async (values, { resetForm }) => {
			const response = await createSubCategoryApi(values);
			if (!response.error) {
				resetForm();
				await fetchCategories();
				handleCloseCategoryModal();
			}
		},
	});

	const handleEdit = (user) => {
		navigate(`/editsubcategory/${user._id}`, { state: { user } });
	};

	const handleSearch = async (event) => {
		try {
			const query = event.target.value;
			setSearchQuery(query);
			const response = await searchCategoryApi(query);
			setAdminData(response.data.data);
			setCurrentPage(0);
			setCurrentCategories(response.data.data.slice(0, categoriesPerPage));
		} catch (error) {
			console.error("Error searching:", error);
		}
	};

	const handleCategoriesPerPageChange = (event) => {
		setCategoriesPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	const handlePageClick = ({ selected }) => {
		setCurrentPage(selected);
	};

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Sub Category</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">Sub Category</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<div className="row">
								<div className="col-6">
									<div className="row">
										<form className="navbar-left navbar-form nav-search mr-md-3">
											<div className="input-group btn-round" style={{ position: "relative" }}>
												<input className="form-control" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearch} style={{ paddingLeft: "30px" }} />
												<i
													className="fa fa-search"
													style={{
														position: "absolute",
														left: "10px",
														top: "50%",
														transform: "translateY(-50%)",
														color: "#aaa",
													}}
												></i>
											</div>
										</form>
									</div>
								</div>

								<div className="col-6">
									<Link onClick={openCreateModal} className="btn btn-primary btn-round">
										<span className="btn-label"></span>
										Create New Sub Category
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table id="basic-datatables" className="table table-hover">
										<thead>
											<tr>
												<th> Sub Category Name</th>
												<th>Action</th>
											</tr>
										</thead>

										<tbody>
											{currentCategories.map((category, index) => (
												<tr key={index}>
													<td>{category.name || "N/A"}</td>

													<td>
														<div className="form-button-action">
															<button
																type="button"
																data-toggle="tooltip"
																title=""
																className="btn btn-primary btn-sm"
																data-original-title="Edit Task"
																onClick={() => handleEdit(category)}
																data-tooltip-id="edit-tooltip"
																data-tooltip-content="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<Tooltip id="edit-tooltip" />
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="d-flex justify-content-between align-items-center pagination-container">
									<ReactPaginate
										previousLabel={"Previous"}
										nextLabel={"Next"}
										breakLabel={"..."}
										pageCount={Math.ceil(total / categoriesPerPage)}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={handlePageClick}
										containerClassName={"pagination m-0"}
										pageClassName={"page-item"}
										pageLinkClassName={"page-link"}
										previousClassName={"page-item"}
										previousLinkClassName={"page-link"}
										nextClassName={"page-item"}
										nextLinkClassName={"page-link"}
										breakClassName={"page-item"}
										breakLinkClassName={"page-link"}
										activeClassName={"active"}
									/>

									<div>
										<span>Records Per Page: </span>
										<select
											id="categoriesPerPage"
											value={categoriesPerPage}
											onChange={handleCategoriesPerPageChange}
											className="form-control m-2"
											style={{ width: "auto", display: "inline-block" }}
										>
											{categoriesPerPageOptions.map((option) => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{openCreateModalState && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Sub Category</h5>
								<button type="button" className="close" onClick={handleCloseCategoryModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form onSubmit={formik.handleSubmit}>
								<div className="modal-body">
									<div className="form-group">
										<label className="form-label mt-2">Sub Category Name</label>
										<input
											type="text"
											className={`form-control ${formik.errors.name && formik.touched.name ? "is-invalid" : ""}`}
											placeholder="Sub Category Name"
											name="name"
											value={formik.values.name}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.errors.name && formik.touched.name && <div className="invalid-feedback">{formik.errors.name}</div>}
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={handleCloseCategoryModal}>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Create Sub Category
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SubCategory;
