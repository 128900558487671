
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { fabric } from "fabric";
import VendorMapModal from "./vendorMapModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const MapCanvas = ({ selectedAddress }) => {
  const location = useLocation();
  const { dates } = location.state; // Get the passed data
  console.warn(":::::::::::DATAA::::::::::::ADADDA", dates);
  const { id } = useParams();
  const [date, setDate] = useState();
  const [vendorSequenceData, setVendorSequenceData] = useState([]);
  const [currentSelectedData, setCurrentSelectedData] = useState([]);
  const {
    getVendorListForMapApi,
    removeMarketSequenceApi,
    getMasteLocationList
  } = useApiAxios();
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef();
  const [availableCircles, setAvailableCircles] = useState([]);
  const [vendorIndex, setVendorIndex] = useState(1);
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
  const { postMapImage } = useApiAxios();
  const mapData = date?.mapData;
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [randorm, setRandories] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [marketsMapAddress, setMarketsMapAddress] = useState("");

  // State to store location list, selected location, and the image URL
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(""); // Start with empty string, not null
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Fetch location list when the modal opens
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getMasteLocationList();
        if (response.data?.data) {
          setLocations(response.data?.data); // Store the locations in state
        } else {
          alert("No locations found");
        }
      } catch (error) {
        alert("Failed to fetch locations");
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    fetchLocations();
  }, []); // Re-run effect when `show` changes

  // Handle location selection to update image
  const handleLocationSelect = (event) => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
  
    // Find the selected location by its _id
    const selected = locations.find((location) => String(location._id) === String(locationId));
  
    console.warn("Selected Location Data:", selected);
  
    // Set image and address if a valid location is found, otherwise reset them
    setSelectedImage(selected?.mapImage || null);
    setMarketsMapAddress(selected?.address || "");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(vendorSequenceData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newArray = [];
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      newArray.push({ ...element, id: index + 1, marketSequence: index + 1 });
    }

    setVendorSequenceData(newArray);
  };
  const clearListHandler = async (items) => {
    const ids = items.map((item) => item._id);
    const res = await removeMarketSequenceApi(ids);
  };
  const handleRemoveVendorClick = async (item) => {
    console.log(item._id); // remove api
    await clearListHandler([item]);
    const array = vendorSequenceData.filter((e) => !(e._id === item._id));
    const array2 = [];

    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      array2.push({ ...element, id: index + 1, marketSequence: index + 1 });
    }
    setVendorSequenceData(array2);
  };

  const handleOnDragEndRaw = (result) => {
    if (!result.destination) return;
    const items = Array.from(vendorSequenceData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newArray = [];
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      newArray.push({ ...element, id: index + 1, marketSequence: index + 1 });
    }
    setFilteredData(newArray);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getVendorListForMapApi(id);
      // console.warn("POPOPOPOPOPO",res.data.date.marketMapAddress)
      if (!res.error) {
        setDate(res.data.date);
        setRawData(
          res.data.data.map((item, index) => {
            return { ...item, id: index + 1 };
          })
        );
        const uniqueCategories = [
          ...new Set(
            res.data.data.map((item) => item?.vendor?.category[0]?.name)
          )
        ];
        const uniqueSubCategories = [
          ...new Set(
            res.data.data.map((item) => item?.vendor?.subCategory[0]?.name)
          )
        ];
        setSubcategories(uniqueSubCategories);
        setCategories(uniqueCategories);
        // Extract subcategories for each category
        // const subcategoriesMap = {};
        // uniqueCategories.forEach((category) => {
        //   subcategoriesMap[category] = [
        //     ...new Set(
        //       res.data.data
        //         .filter((item) => item.category === category)
        //         .map((item) => item.subcategory)
        //     ),
        //   ];
        // });

        // setSubcategories(subcategoriesMap);
        const array = [];
        for (let index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          if (element?.marketSequence) {
            array.push({
              name: element.vendor.businessName,
              _id: element._id,
              id: element?.marketSequence ? element?.marketSequence : index + 1,
              index: index,
              marketSequence: element?.marketSequence
                ? element?.marketSequence
                : index + 1,
              vendor: element?.vendor
            });
          }
        }
        array.sort((a, b) => a.marketSequence - b.marketSequence);

        setVendorSequenceData(array);
        setSelectedVendor(res);
      }
    };
    fetchData();
  }, [id]);
  // console.log(data);

  const [marketMapAddress, setMarketMapAddress] = useState(
    date?.marketMapAddress || ""
  );

  useEffect(() => {
    if (date?.marketMapAddress !== marketMapAddress) {
      setMarketMapAddress(date?.marketMapAddress || "");
    }
  }, [date?.marketMapAddress]);

  const handleMarketMapAddressChange = (event) => {
    setMarketMapAddress(event.target.value);
  };

  console.log(date);
  useEffect(() => {
    // If there's a selected address, use its image
    if (selectedLocation) {
      const selectedLoc = locations.find(loc => loc._id === selectedLocation);
      if (selectedLoc?.mapImage) {
        initializeCanvas(selectedLoc.mapImage);
      }
    }
    // Otherwise use the market's default map image
    else if (date?.market?.mapImage) {
      initializeCanvas(date?.market?.mapImage);
    }

    return () => {
      disposeCanvas();
    };
  }, [date, selectedLocation, locations]); // Added selectedLocation and locations to dependencies

  const loadMapImage = (mapImage) => {
    const fullImageUrl = `${IMAGEENDPOINT}/${mapImage}`;
    const fabricCanvas = fabricCanvasRef.current;
    if (!fabricCanvas) {
      return;
    }

    const img = new Image();
    img.src = fullImageUrl;
    img.crossOrigin = "anonymous";

    img.onload = () => {
      try {
        const canvasWidth = 1215;
        const canvasHeight = canvasWidth / 2;

        fabricCanvas.setWidth(canvasWidth);
        fabricCanvas.setHeight(canvasHeight);

        const scale = Math.min(
          canvasWidth / img.width,
          canvasHeight / img.height
        );

        fabricCanvas.clear();

        const fabricImage = new fabric.Image(img, {
          left: 0,
          top: 0,
          originX: "left",
          originY: "top",
          scaleX: scale,
          scaleY: scale,
          selectable: false,
          evented: false
        });

        fabricCanvas.setBackgroundImage(
          fabricImage,
          fabricCanvas.renderAll.bind(fabricCanvas)
        );

        // Only load vendor markers if we're showing the market's default map
        if (!selectedLocation && mapData) {
          fabricCanvas.loadFromJSON(
            JSON.parse(mapData),
            fabricCanvas.renderAll.bind(fabricCanvas),
            () => {
              const circles = fabricCanvas.getObjects("group");
              const existingCircles = circles.map(
                (group) => group.item(1).text
              );
              const highestNumber = Math.max(0, ...existingCircles.map(Number));
              setVendorIndex(highestNumber + 1);

              const allCircleNumbers = Array.from(
                { length: vendorSequenceData.length },
                (_, i) => (i + 1).toString()
              );
              const missingNumbers = allCircleNumbers.filter(
                (num) => !existingCircles.includes(num)
              );
              setAvailableCircles(missingNumbers);
            }
          );
        }

        // Only add click handler if we're showing the market's default map
        if (!selectedLocation) {
          fabricCanvas.on("mouse:down", handleCanvasClick);
        }

      } catch (error) {
        console.error("Error loading the image onto the fabric canvas:", error);
      }
    };

    img.onerror = (error) => {
      console.error("Failed to load image:", error);
    };
  };

  const initializeCanvas = (mapImage) => {
    const canvas = canvasRef.current;
    if (canvas && !fabricCanvasRef.current) {
      fabricCanvasRef.current = new fabric.Canvas(canvas);
      loadMapImage(mapImage);
    }
  };

  const disposeCanvas = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabricCanvas.off("mouse:down", handleCanvasClick);
      fabricCanvas.dispose();
      fabricCanvasRef.current = null;
    }
  };

  const handleCanvasClick = (event) => {
    const { x, y } = event.pointer;
    const fabricCanvas = fabricCanvasRef.current;

    let shapeClicked = false;
    fabricCanvas.forEachObject((obj) => {
      if (obj.type === "group") {
        const { left, top } = obj;
        const circle = obj.item(0);
        const radius = circle.radius * circle.scaleX;

        const distance = Math.sqrt(
          Math.pow(x - left, 2) + Math.pow(y - top, 2)
        );

        if (distance <= radius) {
          fabricCanvas.remove(obj);
          setAvailableCircles((prev) => {
            const circleNumber = obj.item(1).text;
            return [...prev, circleNumber];
          });

          shapeClicked = true;
          return;
        }
      }
    });

    if (!shapeClicked) {
      addShapeToCanvas({ x, y });
    }
  };

  const addShapeToCanvas = ({ x, y }) => {
    const highestNumber = Math.max(
      0,
      ...getAllNumberInMap(fabricCanvasRef?.current).map(Number)
    );
    setVendorIndex(highestNumber + 1);
    // Populate availableCircles with missing numbers
    const allCircleNumbers = Array.from(
      { length: vendorSequenceData.length },
      (_, i) => (i + 1).toString()
    );
    const missingNumbers = allCircleNumbers.filter(
      (num) => !getAllNumberInMap(fabricCanvasRef?.current).includes(num)
    );
    console.log(missingNumbers);
    setAvailableCircles(missingNumbers);
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas && missingNumbers.length > 0) {
      const circleRadius = 10;
      const circleNumber = missingNumbers[0]; // Get the first available number

      const text = new fabric.Text(circleNumber, {
        fontSize: 20,
        fill: "white",
        originX: "center",
        originY: "center",
        evented: false
      });

      const circle = new fabric.Circle({
        radius: circleRadius,
        fill: "rgba(0,0,0)",
        originX: "center",
        originY: "center",
        evented: false
      });

      const group = new fabric.Group([circle, text], {
        left: x,
        top: y,
        originX: "center",
        originY: "center"
      });

      fabricCanvas.add(group);

      setAvailableCircles((prev) => prev.slice(1)); // Remove the used number from availableCircles
    }
  };

  const saveCanvasAsImage = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      const mapData = JSON.stringify(fabricCanvas.toJSON());
      const canvasImageURL = fabricCanvas.toDataURL({
        format: "png",
        quality: 1
      });
      const imageBlob = await fetch(canvasImageURL).then((res) => res.blob());
      const formData = new FormData();
      formData.append("mapImage", imageBlob, "canvas_image.png");
      formData.append("mapData", mapData);
      formData.append("marketdateId", date._id);
      formData.append("vendorSequenceData", JSON.stringify(vendorSequenceData));
      formData.append("marketMapAddress", marketsMapAddress); // This will now include either the selected location's address or the original market address

      const response = await postMapImage(formData);
      if (!response.error) {
      }
    }
  };

  const getAllNumberInMap = (data) => {
    const number = [];
    console.log(fabricCanvasRef?.current?._objects[0]?._objects[1]?.text);
    for (let index = 0; index < data?._objects.length; index++) {
      const element = data?._objects[index];
      number.push(element?._objects[1]?.text);
    }
    return number;
  };

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true); // Open modal
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVendor(null);
  };
  console.log(getAllNumberInMap(fabricCanvasRef?.current));
  const handleSubCategoryChange = (e) => {
    console.log(e.target.value);
    setSelectedSubcategory(e.target.value); //
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    console.log(e.target.value);
  };
  console.log(rawData);
  const moveSelectedData = () => {
    const selectedData = filteredData.filter((item) => item.selected);

    const array = vendorSequenceData;
    const array2 = [];
    for (let index = 0; index < selectedData.length; index++) {
      const element = selectedData[index];
      if (!vendorSequenceData.some((item) => item._id === element._id)) {
        array2.push({
          name: element.vendor.businessName,
          _id: element._id,
          id: element?.marketSequence ? element?.marketSequence : index,
          index: index,
          marketSequence: element?.marketSequence
            ? element?.marketSequence
            : vendorSequenceData.length + index,
          vendor: element?.vendor
        });
      }
    }
    const array3 = array.concat(array2);
    const array4 = [];
    for (let index = 0; index < array3.length; index++) {
      const element = array3[index];
      array4.push({ ...element, id: index + 1, marketSequence: index + 1 });
    }
    array4.sort((a, b) => a.marketSequence - b.marketSequence);

    setVendorSequenceData(array4);
    setRandories((pre) => pre + 1);
  };
  console.log(vendorSequenceData);
  useEffect(() => {
    if (!selectedCategory && !selectedSubcategory) {
      setFilteredData(rawData);
    } else if (selectedCategory && !selectedSubcategory) {
      const newData = rawData.filter(
        (item) => item?.vendor?.category[0]?.name === selectedCategory
      );

      setFilteredData(newData);
    } else if (!selectedCategory && selectedSubcategory) {
      const newData = rawData.filter(
        (item) => item?.vendor?.subCategory[0]?.name === selectedSubcategory
      );
      setFilteredData(newData);
    } else if (selectedCategory && selectedSubcategory) {
      let newData = rawData.filter(
        (item) =>
          item?.vendor?.category[0]?.name === selectedCategory &&
          item?.vendor?.subCategory[0]?.name === selectedSubcategory
      );

      setFilteredData(newData);
    }
  }, [rawData.length, selectedCategory, selectedSubcategory]);

  const selectAllHandler = (e) => {
    // console.log(e.target.checked);
    // setSelectedAll(e.target.checked);
    setFilteredData((data) =>
      data.map((item) => {
        return { ...item, selected: e.target.checked };
      })
    );
  };
  const singleSelectedHandler = (e, id) => {
    setFilteredData((data) =>
      data.map((item) => {
        if (item._id === id) {
          return { ...item, selected: e.target.checked };
        } else {
          return item;
        }
      })
    );
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fabricCanvas = fabricCanvasRef.current;
      const imageUrl = URL.createObjectURL(file);

      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        try {
          const canvasWidth = 1215;
          const canvasHeight = canvasWidth / (img.width / img.height);

          fabricCanvas.setWidth(canvasWidth);
          fabricCanvas.setHeight(canvasHeight);

          const scale = Math.min(
            canvasWidth / img.width,
            canvasHeight / img.height
          );

          fabricCanvas.clear();

          const fabricImage = new fabric.Image(img, {
            left: 0,
            top: 0,
            originX: "left",
            originY: "top",
            scaleX: scale,
            scaleY: scale,
            selectable: false,
            evented: false
          });

          fabricCanvas.setBackgroundImage(
            fabricImage,
            fabricCanvas.renderAll.bind(fabricCanvas)
          );
          fabricCanvas.renderAll();

          console.log("Image uploaded and displayed on the canvas");
        } catch (error) {
          console.error("Error loading image onto canvas:", error);
        }
      };

      img.onerror = (error) => {
        console.error("Failed to load image:", error);
      };
    }
  };
  return (
    <>
      <p style={{ display: "none" }}>{randorm}</p>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Market Map</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/map">Map</Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="#">Market Map</Link>
                </li>
              </ul>
             

              {/* Show image of the selected location */}
              {/* {selectedImage && (
                <div>
                  <h5>Selected Location Image:</h5>
                  <img
                    width="100%"
                    src={`${process.env.REACT_APP_IMG_URL}/${selectedImage}`} // Ensure correct image URL
                    alt="Selected Location"
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
      
        <div className="row">
        <div className="mb-3">
                <label htmlFor="locationSelect">Select Location</label>
                <select
                  id="locationSelect"
                  className="form-control"
                  value={selectedLocation}
                  onChange={handleLocationSelect}
                  disabled={loading}
                >
                  <option value="">Select a location</option>
                  {loading ? (
                    <option>Loading...</option>
                  ) : (
                    locations.map((location) => (
                      <option key={location._id} value={location._id}>
                        {" "}
                        {/* Use _id for comparison */}
                        {location.address}
                      </option>
                    ))
                  )}
                </select>
              </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="canvas-container">
                  {/* <canvas ref={canvasRef} /> */}
                  <canvas ref={canvasRef} width="1215" height="607.5" />
                </div>
                <div className="row mt-5">
                  <div className=" col-4 offset-1">
                    <div className="input-group mb-3">
                      <div className="d-flex align-items-center">
                        
                        <div className="col p-0 ">
                          <select
                            onChange={handleCategoryChange}
                            value={selectedCategory}
                            className="custom-select"
                          >
                            <option value="">Select a Category</option>
                            {categories.map((category) => (
                              <option key={category} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      {filteredData.length !== 0 ? (
                        <DragDropContext onDragEnd={handleOnDragEndRaw}>
                          <Droppable droppableId="table">
                            {(provided) => (
                              <table
                                className="table table-bordered"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className=""
                                      style={{ minWidth: "36px" }}
                                    >
                                      <input
                                        className="form-check-input mr-1"
                                        type="checkbox"
                                        defaultValue=""
                                        style={{ marginLeft: 0 }}
                                        checked={
                                          !filteredData.some(
                                            (item) => !item.selected
                                          )
                                        }
                                        onClick={selectAllHandler}
                                      />
                                    </th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Sub Category</th>
                                    <th>View</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredData.map((item, index) => (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <td className="">
                                            <input
                                              style={{ marginLeft: 0 }}
                                              className="form-check-input mr-1"
                                              type="checkbox"
                                              defaultValue=""
                                              checked={item.selected}
                                              onClick={(e) =>
                                                singleSelectedHandler(
                                                  e,
                                                  item._id
                                                )
                                              }
                                            />
                                          </td>
                                          <td>{item.vendor?.businessName}</td>
                                          <td>
                                            {item.vendor?.subCategory[0]?.name}
                                          </td>
                                          <td>
                                            {item.vendor?.category[0]?.name}
                                          </td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleVendorClick(item.vendor)
                                              }
                                              className="btn btn-primary btn-sm"
                                            >
                                              View{" "}
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p>No Vendor for this date</p>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ height: "100%" }}
                    >
                      <div title="Add Value" id="arrow-1" className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={moveSelectedData}
                        >
                          <i class="fas fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                      <div title="Remove Value" id="arrow-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            clearListHandler(vendorSequenceData);
                            setVendorSequenceData([]);
                          }}
                        >
                          Clear List
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="input-group"
                      style={{ "margin-bottom": "3.7rem" }}
                    ></div>
                    <div>
                      {vendorSequenceData.length !== 0 ? (
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="table">
                            {(provided) => (
                              <table
                                className="table table-bordered"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>View</th>
                                    <th>Remove</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendorSequenceData.map((item, index) => (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <td>{item.id}</td>
                                          <td>{item.name}</td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleVendorClick(item.vendor)
                                              }
                                              className="btn btn-primary btn-sm"
                                            >
                                              View
                                            </button>
                                          </td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleRemoveVendorClick(item)
                                              }
                                              className="btn btn-danger btn-sm"
                                            >
                                              Remove
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p>No Vendor for this date</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-12 modal-contentimage">
                    <button
                      type="button"
                      onClick={saveCanvasAsImage}
                      className="btn btn-primary"
                    >
                      Save Map
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VendorMapModal
        vendor={selectedVendor}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default MapCanvas;
