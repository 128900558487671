import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { useNavigate } from "react-router-dom";

// Custom input component with calendar icon for DatePicker
const CustomDateInput = React.forwardRef(
  ({ value, onClick, placeholderText }, ref) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        onClick={onClick}
        value={value}
        readOnly
        ref={ref}
        placeholder={placeholderText}
      />
      <div className="input-group-append">
        <span className="input-group-text" onClick={onClick}>
          <i className="fa fa-calendar" />
        </span>
      </div>
    </div>
  )
);

// Custom DatePicker field component for Formik using the custom input
const DatePickerField = ({ field, form, ...props }) => {
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => form.setFieldValue(field.name, val)}
      customInput={<CustomDateInput placeholderText={props.placeholderText} />}
      dateFormat="dd/MM/yyyy"
      minDate={props.minDate || new Date()}
      showTimeSelect={false}
    />
  );
};

// Custom Select field component for Formik using react-select
const SelectField = ({ field, form, options, placeholder }) => {
  const onChange = (option) => {
    form.setFieldValue(field.name, option.value);
  };

  const selectedOption = options.find((option) => option.value === field.value);

  return (
    <Select
      name={field.name}
      value={selectedOption}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

const typeOptions = [
  { value: "event", label: "Event" },
  { value: "festival", label: "Festival" }
];

const paymentOptions = [
  { value: "paid", label: "Paid" },
  { value: "free", label: "Free" }
];

const CreateEventFestival = () => {
  const { createEventFestivalAPI } = useApiAxios();
  const { userData } = useContext(Context);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageTouched, setImageTouched] = useState(false);
  const initialValues = {
    eventName: "",
    startDate: null,
    endDate: null,
    description: "",
    type: "",
    paymentType: "paid",
    amount: "",
    image: null,
    address: ""
  };

  const validationSchema = Yup.object({
    eventName: Yup.string().required("Event Name is required"),
    startDate: Yup.date().nullable().required("Start Date is required"),
    endDate: Yup.date()
      .nullable()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date must be after Start Date"),
    description: Yup.string().required("Description is required"),
    type: Yup.string()
      .oneOf(["event", "festival"], "Invalid type")
      .required("Type is required"),
    paymentType: Yup.string()
      .oneOf(["paid", "free"], "Invalid payment type")
      .required("Payment Type is required"),
    amount: Yup.number()
  .transform((value, originalValue) =>
    originalValue === "" || originalValue === null ? undefined : value
  )
  .when("paymentType", {
    is: "paid",
    then: (schema) =>
      schema
        .typeError("Amount must be a valid number")
        .required("Amount is required for paid events")
        .positive("Amount must be greater than 0")
        .integer("Amount must be a whole number"),
    otherwise: (schema) => schema.notRequired()
  }),

    image: Yup.mixed()
      .required("Image is required")
      .test("fileType", "Only JPG, JPEG, and PNG are allowed", (value) => {
        if (!value) return false;
        return (
          value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
        );
      }),
    address: Yup.string().required("Address is required")
  });

  const formatToISOString = (date) => {
    if (!date) return null;
    const localDate = new Date(date);
    localDate.setHours(12, 0, 0, 0);
    return localDate.toISOString();
  };

  const onSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm, validateForm }
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("eventName", values.eventName);
    formData.append("description", values.description);
    formData.append("address", values.address);
    formData.append("type", values.type);
    formData.append("paymentType", values.paymentType);
    formData.append("amount", values.amount);
    formData.append("startDate", formatToISOString(values.startDate));
    formData.append("endDate", formatToISOString(values.endDate));

    if (values.image) {
      formData.append("image", values.image);
    }
    formData.append("marketOwnerId", userData?._id);

    try {
      const response = await createEventFestivalAPI(formData);
      console.log("Event created successfully:", response.data);
      navigate("/event-festival");
      resetForm();
    } catch (error) {
      console.error("Error creating event:", error);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Create Events & Festival</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/createEvent-Festival">
                    Create Events & Festival
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="page-inner mt--5">
        <div className="col-md-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              errors,
              touched,
              isValid,
              dirty,
              setErrors,
              setFieldTouched,
              setFieldError
            }) => (
              <Form>
                <div className="mb-3">
                  <label className="form-label">Event Name</label>
                  <Field
                    type="text"
                    name="eventName"
                    className="form-control"
                    placeholder="Enter event name"
                  />
                  <ErrorMessage
                    name="eventName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <Field
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Enter event address"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-4">
                

                  {/* Start Date */}
                  <div className="mb-3">
                    <label className="form-label fw-semibold">Start Date</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-calendar-event"></i>{" "}
                        {/* Bootstrap Calendar Icon */}
                      </span>
                      <Field
                        name="startDate"
                        component={DatePickerField}
                        dateFormat="Pp"
                        placeholderText="Select start date"
                        className="form-control"
                      />
                    </div>
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>

                  {/* End Date */}
                  <div className="mb-3">
                    <label className="form-label fw-semibold">End Date</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-calendar-event"></i>
                      </span>
                      <Field
                        name="endDate"
                        component={DatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select end date"
                        minDate={values.startDate}
                        className="form-control"
                      />
                    </div>
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <Field
                    as="textarea"
                    name="description"
                    className="form-control"
                    placeholder="Describe the event"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Type</label>
                  <Field
                    name="type"
                    component={SelectField}
                    options={typeOptions}
                    placeholder="Select type"
                  />
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Payment Type</label>
                  <Field
                    name="paymentType"
                    component={SelectField}
                    options={paymentOptions}
                    placeholder="Select payment type"
                  />
                  <ErrorMessage
                    name="paymentType"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {values.paymentType === "paid" && (
                  <div className="mb-3">
                    <label className="form-label">Amount</label>
                    <Field
                      type="number"
                      name="amount"
                      className="form-control"
                      placeholder="Enter amount"
                      min="1"
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                )}

                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className={`form-control ${
                      imageTouched && errors.image ? "is-invalid" : ""
                    }`}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setImageTouched(true); // Mark as touched when file is selected
                      console.log("Selected file:", file);

                      if (file) {
                        const allowedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg"
                        ];
                        console.log("File type:", file.type);

                        if (!allowedFormats.includes(file.type)) {
                          console.warn(
                            "Invalid file type detected:",
                            file.type
                          );
                          setFieldError(
                            "image",
                            "Only JPG, JPEG, and PNG files are allowed"
                          );
                          setImagePreview(null);
                          event.target.value = ""; // Clear the file input
                        } else {
                          console.log("Valid file type");
                          setFieldError("image", "");
                          setFieldValue("image", file);
                          const reader = new FileReader();
                          reader.onloadend = () =>
                            setImagePreview(reader.result);
                          reader.readAsDataURL(file);
                        }
                      } else {
                        console.log("No file selected");
                        setImagePreview(null);
                        setFieldValue("image", null);
                      }
                    }}
                    onBlur={() => setImageTouched(true)} // Mark as touched when blurred
                  />

                  {/* Image Preview */}
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          borderRadius: "4px",
                          border: "1px solid #ddd"
                        }}
                      />
                    </div>
                  )}

                  {/* Error Message - Only show if touched */}
                  {imageTouched && errors.image && (
                    <div className="invalid-feedback d-block">
                      {errors.image}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {isSubmitting ? "Creating..." : "Create Event"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateEventFestival;
