import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const VendorMarket = () => {
  const { userData } = useContext(Context);
  const vendor = userData.vendor;
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
console.warn("ssss",vendor)
  useEffect(() => {
    document.title = "Market Details - Eventease360";
  }, []);

  // Data comes from vendor.marketIds array
  const data = vendor?.marketIds || [];

  // Pagination State
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    setCurrentPage(0); // Reset to first page when itemsPerPage changes
  }, [itemsPerPage]);

  const offset = currentPage * itemsPerPage;
  const currentData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div>
      {/* Header & Breadcrumb */}
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-start flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Market Details</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/marketDetails">Market Details</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="container-fluid">
        <h3 className="fw-bold text-primary mb-3" style={{ fontSize: "2.5rem" }}>
          Market for Business: {vendor?.businessName || "Not Available"}
        </h3>
        <div className="table-responsive">
          <table className="table table-hover" style={{ fontSize: "1.2rem" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1.5rem" }}>Market Name</th>
                <th style={{ fontSize: "1.5rem" }}>Image</th>
                <th style={{ fontSize: "1.5rem" }}>Approval Status</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((market, index) => {
                const approval = vendor?.approvalStatus?.find(
                  (item) => item.marketId === market._id
                );
                const statusText = approval ? approval.status : "Not Available";
                let badgeClass = "badge-secondary";
                if (approval) {
                  switch (approval.status.toLowerCase()) {
                    case "approved":
                      badgeClass = "badge-success";
                      break;
                    case "declined":
                      badgeClass = "badge-danger";
                      break;
                    case "waitlisted":
                      badgeClass = "badge-warning";
                      break;
                    default:
                      badgeClass = "badge-secondary";
                  }
                }

                return (
                  <tr key={index}>
                    <td style={{ padding: "15px" }}>{market.name}</td>
                    <td style={{ padding: "15px" }}>
                      <img
                        src={`${IMAGEENDPOINT}/${market.image}`}
                        alt={market.name}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </td>
                    <td style={{ padding: "15px" }}>
                      <span className={`badge ${badgeClass}`} style={{ fontSize: "1.2rem", padding: "10px 15px" }}>{statusText}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Items Per Page Dropdown at Bottom */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <label htmlFor="itemsPerPage" className="me-2">Item Per Page:</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              // className="form-select d-inline w-auto"
              className="form-control m-2"

            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
          </div>
          
          {/* Pagination */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorMarket;
