import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";
import { Modal, Button } from "react-bootstrap"; // For modal
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import "./eventFestival.css";

const EventFestival = () => {
  const { userData } = useContext(Context);
  const [loading, setLoading] = useState(false);

  // Items per page state and options
  const [marketPerPage, setMarketPerPage] = useState(10);
  const marketPerPageOptions = [10, 20, 30, 50];

  // Current page state
  const [currentPage, setCurrentPage] = useState(0);

  // State for Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const {
    getAllEventFestivalApi,
    approveVendorEvent,
    rejectVendorEvent,
    deleteEventApi
  } = useApiAxios();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState(null);
  // State to store API data
  const [eventAndFestivalData, setEventAndFestivalData] = useState([]);

  const navigate = useNavigate();

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllEventFestivalApi();
        console.warn("Fetched event festival data:", response);
        // Adjust response.data depending on your API structure
        setEventAndFestivalData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching event festival data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenDeleteModal = (id) => {
    setDeleteEventId(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteEventApi(deleteEventId);
      setEventAndFestivalData((prevData) =>
        prevData.filter((event) => event._id !== deleteEventId)
      );
    } catch (error) {
      console.error("Error deleting event", error);
    } finally {
      setShowDeleteModal(false);
      setDeleteEventId(null);
    }
  };

  // Handle change for items per page; reset current page to 0
  const handleVendorsPerPageChange = (e) => {
    setMarketPerPage(Number(e.target.value));
    setCurrentPage(0);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleShowDetails = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  // Approve an event using the approveVendorEvent API
  const handleApprove = async (eventId) => {
    try {
      const response = await approveVendorEvent(eventId);
      console.log("Approve response:", response.data);
      // Update the event's status locally
      setEventAndFestivalData((prevData) =>
        prevData.map((ev) =>
          ev._id === eventId ? { ...ev, approvalStatus: "approved" } : ev
        )
      );
    } catch (error) {
      console.error("Error approving event", error);
    }
  };

  // Disapprove an event using the rejectVendorEvent API
  const handleDisapprove = async (eventId) => {
    try {
      const response = await rejectVendorEvent(eventId);
      console.log("Disapprove response:", response.data);
      // Update the event's status locally
      setEventAndFestivalData((prevData) =>
        prevData.map((ev) =>
          ev._id === eventId ? { ...ev, approvalStatus: "declined" } : ev
        )
      );
    } catch (error) {
      console.error("Error disapproving event", error);
    }
  };

  const handleEdit = (item) => {
    navigate("/editEventFestival", { state: { eventData: item } });
  };

  // Calculate pagination values
  const offset = currentPage * marketPerPage;
  const paginatedData = eventAndFestivalData.slice(
    offset,
    offset + marketPerPage
  );
  const pageCount = Math.ceil(eventAndFestivalData.length / marketPerPage);

  return (
    <div>
      <div className="">
        <div className="panel-header">
          <div className="page-inner pb-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="pb-2 fw-bold">Events & Festival</h2>
                <ul className="breadcrumbs">
                  <li className="nav-home">
                    <Link to="/">
                      <i className="flaticon-home" />
                    </Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <Link to="/event-festival">Events & Festival</Link>
                  </li>
                </ul>
              </div>
              <div className="ml-md-auto py-2 py-md-0">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <Link
                          to="/createEvent-Festival"
                          className="btn btn-primary btn-round"
                        >
                          <span className="btn-label"></span>
                          Create New Events & Festival
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display loading state if API call is in progress */}
        {loading ? (
          <div className="text-center my-4">Loading...</div>
        ) : (
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Event</th>{" "}
                            {/* Changed from separate Name and Image columns */}
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Address</th>
                            <th>Type</th>
                            <th>Payment Type</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {paginatedData.map((event, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={`${process.env.REACT_APP_IMG_URL}/${event.image}`}
                                    alt="Event"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>{event.eventName || "N/A"}</span>
                                </div>
                              </td>
                              <td>{formatDate(event.startDate)}</td>
                              <td>{formatDate(event.endDate)}</td>
                              <td>{event.address ? event.address : "N/A"}</td>

                              <td>
                                <span
                                  className={`badge ${
                                    event.type === "Festival"
                                      ? "badge-success"
                                      : "badge-info"
                                  }`}
                                >
                                  {event.type || "N/A"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    event.paymentType === "Free"
                                      ? "badge-primary"
                                      : "badge-warning"
                                  }`}
                                >
                                  {event.paymentType || "N/A"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    event.approvalStatus === "approved"
                                      ? "badge-success"
                                      : event.approvalStatus === "declined"
                                      ? "badge-danger"
                                      : "badge-warning"
                                  }`}
                                >
                                  {event.approvalStatus || "Pending"}
                                </span>
                              </td>
                              <td>
                                <div className="form-button-action">
                                  <button
                                    className="btn btn-primary btn-sm mr-1"
                                    onClick={() => handleEdit(event)}
                                  >
                                    <i className="fa fa-edit" />
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm mr-1"
                                    onClick={() =>
                                      handleOpenDeleteModal(event._id)
                                    }
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                      <i className="fa fa-trash" />
                                    )}
                                  </button>
                                  <button
                                    className="btn btn-info btn-sm mr-1"
                                    onClick={() => handleShowDetails(event)}
                                    data-tooltip-id="view-tooltip"
                                    data-tooltip-content="View Details"
                                  >
                                    <i className="fa fa-eye" />
                                  </button>
                                  <Tooltip id="view-tooltip" />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* Pagination and items per page dropdown */}
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="d-flex align-items-center">
                        <label htmlFor="marketPerPageSelect" className="me-2">
                          Items per page:
                        </label>
                        <select
                          id="marketPerPageSelect"
                          value={marketPerPage}
                          onChange={handleVendorsPerPageChange}
                          className="form-control m-2"
                          style={{ width: "auto" }}
                        >
                          {marketPerPageOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modal for Viewing Details */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-responsive"
      >
        <Modal.Header>
          <Modal.Title className="fw-bold text-primary">
            Event & Festival Details
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          {selectedEvent && (
            <div className="p-2">
              {/* Event Name & Date */}
              <div className="text-center mb-3">
                <h4 className="fw-bold text-uppercase">
                  {selectedEvent.eventName}
                </h4>
                <p className="text-muted">
                  {formatDate(selectedEvent.startDate)} -{" "}
                  {formatDate(selectedEvent.endDate)}
                </p>
              </div>

              {/* Responsive Layout */}
              <div className="d-flex flex-column flex-md-row align-items-center">
                {/* Event Image */}
                <div className="col-12 col-md-5 text-center mb-3 mb-md-0">
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/${selectedEvent.image}`}
                    alt={selectedEvent.eventName}
                    className="shadow-sm event-img"
                  />
                </div>

                {/* Event Details */}
                <div className="col-12 col-md-7">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <strong>Type:</strong>
                      <span
                        className={`badge ${
                          selectedEvent.type === "Festival"
                            ? "badge-success"
                            : "badge-info"
                        } ms-2`}
                      >
                        {selectedEvent.type}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <strong>Payment Type:</strong>
                      <span
                        className={`badge ${
                          selectedEvent.paymentType === "Free"
                            ? "badge-primary"
                            : "badge-warning"
                        } ms-2`}
                      >
                        {selectedEvent.paymentType}
                      </span>
                    </li>

                    {/* Amount Display (Only for Paid Events) */}
                    {selectedEvent?.paymentType === "paid" && (
                      <li className="list-group-item">
                        <strong>Amount:</strong> ${selectedEvent.amount}
                      </li>
                    )}

                    <li className="list-group-item">
                      <strong>Total Stalls:</strong> {selectedEvent.totalstall}
                    </li>
                    <li className="list-group-item">
                      <strong>Status:</strong>
                      <span
                        className={`badge ${
                          selectedEvent.approvalStatus === "approved"
                            ? "badge-success"
                            : selectedEvent.approvalStatus === "declined"
                            ? "badge-danger"
                            : "badge-warning"
                        } ms-2`}
                      >
                        {selectedEvent.approvalStatus || "Pending"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <strong>Address:</strong>
                      <span className="ms-2">
                        {selectedEvent.address || "N/A"}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <strong>Description:</strong>
                      <p className="text-muted" style={{ lineHeight: "1.6" }}>
                        {selectedEvent.description}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Description Section */}
              {/* <div className="mt-3">
          <h5 className="fw-bold">Description</h5>
          <p style={{ lineHeight: "1.6" }}>
            {selectedEvent.Description}
          </p>
        </div> */}
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirm Deletion</Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShowDeleteModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this event?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventFestival;
