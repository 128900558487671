import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import moment from "moment"; // Import moment
import { Context } from "../../context/Context";
import { Modal, Button, Form } from "react-bootstrap";

const VendorPaymentList = () => {
  document.title = "Eventease360 | Payment Requests";
  const {
    getAllInvoiceVendor,
    getAllVendor,
    searchgetInvoiceSearchHistoryApi,
    getVendorDateInvoicePaymentUrl,
    updateVendorPaymentSubscriptionApi,
    getMarketOwnerBankDetail,
    postVendorManualTransaction
  } = useApiAxios();
  const { userData } = useContext(Context);
  console.warn("USER DATA", userData);
  const ownerId = userData.vendor.owner;
  console.warn("owner id ", ownerId);

  const [payments, setPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [useWallet, setUseWallet] = useState(false);
  const [manualTransactionId, setManualTransactionId] = useState(""); // State to store entered transaction ID
  const [transactionIdError, setTransactionIdError] = useState("");

  const [bankPaymentDetails, setBankPaymentDetails] = useState({
    bankAccount: "",
    ifscCode: "",
    customerName: "",
    paymentAmount: 0
  });

  const [ownerBankDetails, setOwnerBankDetails] = useState(null); // To hold the fetched bank details

  const handleWalletChange = (event) => {
    setUseWallet(event.target.checked);
  };

  const fetchData = async (page = 1, limit = 1000) => {
    try {
      const vendorResponse = await getAllVendor();
      setVendors(vendorResponse?.data?.data);

      const invoiceResponse = await getAllInvoiceVendor(page, limit);
      console.warn("alll ivncoie api", invoiceResponse)

      setPayments(invoiceResponse?.data?.data);
      setTotalPages(invoiceResponse?.data?.data.totalPages);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchgetInvoiceSearchHistoryApi(query);
      setPayments(response.data.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const openPaymentModal = async (payment) => {
    setSelectedPayment(payment);
    setModalVisible(true);

    // Fetch bank details when the modal is opened
    try {
      const bankResponse = await getMarketOwnerBankDetail(ownerId);
      setOwnerBankDetails(bankResponse.data.data); // Store the fetched bank details
    } catch (error) {
      console.error("Error fetching bank details:", error);
      setErrorMessage("Error fetching bank details.");
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedPayment(null);
    setBankPaymentDetails({
      bankAccount: "",
      ifscCode: "",
      customerName: "",
      paymentAmount: 0
    });
    setOwnerBankDetails(null); // Clear bank details when closing the modal
  };

  const DateComponent = (data) => {
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return data?.map((item) => formatDate(item?.dateId?.date)).join(", ");
  };

  const makePaymentHandler = async (id, walletAmount) => {
    try {
      const totalAmount =
        selectedPayment.price * selectedPayment.vendorDates.length;
      let finalWalletAmount = 0;

      if (walletAmount >= totalAmount) {
        finalWalletAmount = totalAmount;
        const remainingWalletAmount =
          selectedPayment.vendorId.walletAmount - finalWalletAmount;
        if (remainingWalletAmount < 0) {
          finalWalletAmount = selectedPayment.vendorId.walletAmount;
        }
        await updateVendorPaymentSubscriptionApi(id, remainingWalletAmount);
      } else {
        finalWalletAmount = walletAmount;
      }

      const { data, error } = await getVendorDateInvoicePaymentUrl({
        vendorInvoiceId: id,
        walletAmount: finalWalletAmount
      });

      if (error || !data || !data.data.sessionUrl) {
        setErrorMessage("Failed to initiate payment. Please try again.");
        return;
      }

      window.location.href = data.data.sessionUrl;
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  const handleManualPayment = async (
    marketOwnerId,
    transactionId,
    accountId,
    amount,
    vendorInvoiceId
  ) => {
    try {
      // Validate if transaction ID is entered
      if (!transactionId) {
        setTransactionIdError("Transaction ID is required.");
        return; // Stop the payment process if no transaction ID is entered
      }

      // Reset the error if the field is filled
      setTransactionIdError("");

      // Proceed with the payment if the transaction ID is valid
      const payload = {
        marketOwnerId,
        transactionId, // Use the transaction ID provided by the user
        accountId, // This is the account number
        amount,
        vendorInvoiceId
      };

      // Call the API to post the manual transaction
      const response = await postVendorManualTransaction(payload);

      // Check for the response and handle success or failure
      if (response.error) {
        setErrorMessage("Error posting manual transaction. Please try again.");
      } else {
        // Handle successful payment submission
        console.log("Manual transaction successful:", response.data);

        // Close the modal after successful payment
        closeModal();

        // Optionally, you can add a success message or handle further UI updates
        // setSuccessMessage("Payment successfully processed.");
      }
    } catch (error) {
      console.error("Error calling postVendorManualTransaction:", error);
      setErrorMessage("An error occurred while processing the transaction.");
    }
  };

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Payment Requests</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendor/paymenthistory">Payment Requests</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div
          className="mt-3 alert alert-danger"
          style={{ padding: "15px 30px" }}
        >
          {errorMessage}
        </div>
      )}

      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Sub Admin Name</th>
                      <th>Sub Admin Email</th>
                      <th>Vendor Name</th>
                      <th>Payment Date</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.length > 0 ? (
                      payments.map((payment) => {
                        const vendorDatesLength =
                          payment?.vendorDates?.length || 0;
                        const calculatedPrice =
                          payment?.price * vendorDatesLength;
                        return (
                          <tr key={payment._id}>
                            <td>{payment?.subAdmin?.name}</td>
                            <td>{payment?.subAdmin?.email}</td>
                            <td>{payment?.vendorId?.businessName}</td>
                            <td>
                              {new Date(
                                payment?.vendorId?.updatedAt
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric"
                              })}
                            </td>
                            <td>${calculatedPrice}</td>
                            <td>{DateComponent(payment?.vendorDates)}</td>
                            <td>
                              {payment.status === "InvoiceSend" ? (
                                <button
                                  onClick={() => openPaymentModal(payment)}
                                  className="btn btn-primary btn-sm"
                                >
                                  Pay
                                </button>
                              ) : (
                                payment.status
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No payments found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalVisible && selectedPayment && (
        <Modal show={modalVisible} onHide={closeModal} size="lg" scrollable>
          <Modal.Header>
            <Modal.Title>Payment Details</Modal.Title>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              style={{ fontSize: "30px" }}
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <th>Vendor Name:</th>
                      <td>{selectedPayment?.vendorId?.businessName}</td>
                    </tr>
                    <tr>
                      <th>Sub Admin:</th>
                      <td>
                        {selectedPayment?.subAdmin?.name} (
                        {selectedPayment?.subAdmin?.email})
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Vendor Price:</th>
                      <td>${selectedPayment?.price}</td>
                    </tr>
                    <tr>
                      <th>Payment Date:</th>
                      <td>
                        {new Date(
                          selectedPayment?.vendorId?.updatedAt
                        ).toLocaleDateString()}
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status:</th>
                      <td>{selectedPayment?.status}</td>
                    </tr> */}
                    {/* <tr>
                      <th>Wallet Amount:</th>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-3">
                            ${selectedPayment?.vendorId?.walletAmount}
                          </span>
                          <Form.Check
                            type="checkbox"
                            id="useWallet"
                            checked={useWallet}
                            onChange={handleWalletChange}
                            label="Use Wallet Amount"
                            className="walletchech"
                            style={{
                              left: "0 !important"
                            }}
                          />
                        </div>
                        {useWallet &&
                          selectedPayment?.vendorId?.walletAmount > 0 && (
                            <div className="font-weight-bold mt-2">
                              Remaining Payment After Wallet Deduction: $
                              {selectedPayment?.price *
                                selectedPayment?.vendorDates?.length >
                              selectedPayment?.vendorId?.walletAmount
                                ? (
                                    selectedPayment?.price *
                                      selectedPayment?.vendorDates?.length -
                                    selectedPayment?.vendorId?.walletAmount
                                  ).toFixed(2)
                                : "0.00"}
                            </div>
                          )}
                      </td>
                    </tr> */}

                    {selectedPayment.vendorId.walletAmount > 0 && (
                      <tr>
                        <th>Wallet Amount:</th>
                        <td>
                          <input
                            type="checkbox"
                            id="useWallet"
                            checked={useWallet}
                            onChange={handleWalletChange}
                            className="mr-2"
                          />
                          {/* <label
                            className="form-check-label"
                            htmlFor="useWallet"
                          >
                            Use Wallet Amount
                          </label>{" "} */}
                          ${selectedPayment.vendorId.walletAmount}
                          {/* {useWallet && (
                            <h4 className="font-weight-bold">
                              Remaining Payment After Wallet Deduction: $
                              {selectedPayment.price *
                                selectedPayment.vendorDates.length >
                              selectedPayment.vendorId.walletAmount
                                ? (
                                    selectedPayment.price *
                                      selectedPayment.vendorDates.length -
                                    selectedPayment.vendorId.walletAmount
                                  ).toFixed(2)
                                : "0.00"}
                            </h4>
                          )} */}
                        </td>
                        

                      </tr>
                    )}

                    <tr>
                    <td colSpan="2">
                    {useWallet && (
                            <h4 className="font-weight-bold" style={{ color: "#3e8ede" }}>
                              Remaining Payment After Wallet Deduction: $
                              {selectedPayment.price *
                                selectedPayment.vendorDates.length >
                              selectedPayment.vendorId.walletAmount
                                ? (
                                    selectedPayment.price *
                                      selectedPayment.vendorDates.length -
                                    selectedPayment.vendorId.walletAmount
                                  ).toFixed(2)
                                : "0.00"}
                            </h4>
                          )}
                        </td>
                        </tr>
                   
                   <tr className="table-active">
                      <th>Total Price:</th>
                      <td className="font-weight-bold h2">
                        $
                        {selectedPayment?.price *
                          selectedPayment?.vendorDates?.length}
                      </td>
                    </tr>
                    <tr>
                      <th>Transaction ID:</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={manualTransactionId}
                          onChange={(e) =>
                            setManualTransactionId(e.target.value)
                          }
                          placeholder="Enter transaction ID"
                          isInvalid={!!transactionIdError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {transactionIdError}
                        </Form.Control.Feedback>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="border rounded-3 bg-light">
                        {ownerBankDetails && (
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <Form.Label className="font-weight-bold">
                                Account Number:
                              </Form.Label>
                              <p className="mb-0 text-primary">
                                {ownerBankDetails.accountNumber}
                              </p>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <Form.Label className="font-weight-bold">
                                BSB Code:
                              </Form.Label>
                              <p className="mb-0 text-primary">
                                {ownerBankDetails.ifscCode}
                              </p>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <Form.Label className="font-weight-bold">
                                Bank Name:
                              </Form.Label>
                              <p className="mb-0 text-primary">
                                {ownerBankDetails.bankName}
                              </p>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <Form.Label className="font-weight-bold">
                                Account Holder Name:
                              </Form.Label>
                              <p className="mb-0 text-primary">
                                {ownerBankDetails.customerName}
                              </p>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                makePaymentHandler(
                  selectedPayment._id,
                  useWallet ? selectedPayment.vendorId.walletAmount : 0
                )
              }
            >
              Proceed with Payment
            </Button>
            <Button
              variant="success"
              onClick={() => {
                if (!manualTransactionId) {
                  setTransactionIdError("Transaction ID is required.");
                } else {
                  handleManualPayment(
                    ownerId,
                    manualTransactionId,
                    ownerBankDetails?.accountNumber,
                    selectedPayment.price * selectedPayment.vendorDates.length,
                    selectedPayment._id
                  );
                }
              }}
            >
              Pay Via Bank Account
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default VendorPaymentList;
