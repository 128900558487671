import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from "react-router-dom";
import { Context } from "../../context/Context";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

const ViewMarket = () => {
	document.title = "Eventease360 | Market";
	const { getAllMarket, deleteMarketApi, searchMarketApi } = useApiAxios();
	const [adminData, setAdminData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [marketToDelete, setMarketToDelete] = useState(null);

	const [currentPage, setCurrentPage] = useState(0);
	const [marketPerPage, setMarketsPerPage] = useState(10);
	const marketPerPageOptions = [10, 20, 50, 100];
	const [total, setTotal] = useState(0);

	const { trial, userData } = useContext(Context);
	const navigate = useNavigate();
	console.log(trial, adminData.length === 0, userData.subscription);
	const fetchMarketData = async (page, limit) => {
		try {
			const response = await getAllMarket(page, limit);
			if (!response.error) {
				setAdminData(response.data.data);
				setTotal(response.data.total);
			} else {
				console.error("Error fetching data:", response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchMarketData(currentPage + 1, marketPerPage);
	}, [currentPage, marketPerPage]);

	const handleEdit = (user) => {
		navigate(`/editmarket/${user._id}`, { state: { user } });
	};

	const handleOpenDeleteModal = (userId) => {
		setMarketToDelete(userId);
		setDeleteModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
		setMarketToDelete(null);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const handleDeleteMarket = async () => {
		if (marketToDelete) {
			setLoading(true);
			try {
				await deleteMarketApi(marketToDelete);
				await fetchMarketData(currentPage + 1, marketPerPage);
				handleCloseDeleteModal();
			} catch (error) {
				console.error("Error deleting market:", error);
			}
			setLoading(false);
		}
	};

	const handleSearch = async (event) => {
		try {
			const query = event.target.value;
			setSearchQuery(query);
			const response = await searchMarketApi(query);
			setAdminData(response.data.data);
		} catch (error) {
			console.error("Error searching:", error);
		}
	};

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setMarketsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	return (
		<>
			<div className="">
				<div className="panel-header">
					<div className="page-inner pb-5">
						{trial && !userData?.subscription && (
							<div className="alert alert-danger" role="alert" style={{ color: "red" }}>
								{`Your trial period is ending on ${moment(new Date(userData?.trialStartedAt)).format("DD-MY-YYYY")}`}
							</div>
						)}
						<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
							<div>
								<h2 className="pb-2 fw-bold">Market</h2>
								<ul className="breadcrumbs">
									<li className="nav-home">
										<Link to="/">
											<i className="flaticon-home" />
										</Link>
									</li>
									<li className="separator">
										<i className="flaticon-right-arrow" />
									</li>
									<li className="nav-item">
										<Link to="/viewmarket">Market</Link>
									</li>
								</ul>
							</div>
							<div className="ml-md-auto py-2 py-md-0">
								<div className="row">
									<div className="col-6">
										<div className="row">
											<form className="navbar-left navbar-form nav-search mr-md-3">
												<div className="input-group btn-round" style={{ position: "relative" }}>
													<input className="form-control" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearch} style={{ paddingLeft: "30px" }} />
													<i
														className="fa fa-search"
														style={{
															position: "absolute",
															left: "10px",
															top: "50%",
															transform: "translateY(-50%)",
															color: "#aaa",
														}}
													></i>
												</div>
											</form>
										</div>
									</div>
									<div className="col-6">
										<div className="row">
											<div className="col-6">
												{(trial || adminData.length === 0 || userData.subscription) && (
													<Link to="/createmarket" className="btn btn-primary btn-round">
														<span className="btn-label"></span>
														Create New Market
													</Link>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-inner mt--5">
					<div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
												<tr>
													<th>Name</th>
													<th>Total Stall</th>
													<th>Image</th>
													
													<th>Category</th>
													<th>Sub Category</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{adminData.map((user, index) => (
													<tr key={index}>
														<td>{user.name || "N/A"}</td>
														<td>{user.totalstall || "N/A"}</td>
														<td>
															<span className="product-img">
																<img height={"100%"} src={`${process.env.REACT_APP_IMG_URL}/${user.image}`} alt={"image not valid"} />
															</span>
														</td>
														<td>{user.category?.map((obj) => obj.name).join(", ")}</td>
														<td>{user.subCategory?.map((obj) => obj.name).join(", ")}</td>

														<td>
															<div className="form-button-action">
																<button
																	type="button"
																	className="btn btn-primary btn-sm mr-1"
																	onClick={() => handleEdit(user)}
																	data-tooltip-id="edit-tooltip"
																	data-tooltip-content="Edit"
																>
																	<i className="fa fa-edit" />
																</button>
																<button
																	type="button"
																	className="btn btn-danger btn-sm"
																	onClick={() => handleOpenDeleteModal(user._id)}
																	disabled={loading}
																	data-tooltip-id="delete-tooltip"
																	data-tooltip-content="Delete"
																>
																	{loading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-trash" />}
																</button>
																<Tooltip id="edit-tooltip" />
																<Tooltip id="delete-tooltip" />
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className="d-flex justify-content-between align-items-center pagination-container">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={Math.ceil(total / marketPerPage)}
												marginPagesDisplayed={2}
												pageRangeDisplayed={5}
												onPageChange={handlePageClick}
												containerClassName={"pagination m-0"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
											<div>
												<span>Records Per Page: </span>
												<select value={marketPerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
													{marketPerPageOptions.map((option) => (
														<option key={option} value={option}>
															Show {option}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{deleteModalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Delete Market</h5>
								<button type="button" className="close" onClick={handleCloseDeleteModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this market?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" onClick={handleDeleteMarket}>
									Yes
								</button>
								<button type="button" className="btn btn-secondary" onClick={handleCloseDeleteModal}>
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ViewMarket;
