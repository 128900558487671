import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, Container } from "react-bootstrap";
import useApiAxios from "../../api/useApiAxios";

const MarketAssignModal = ({ show, handleClose, vendor }) => {
  const { getAllMarket, assignMarketsToVendor } = useApiAxios();
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false); // For button disable state

  // Handle checkbox selection
  const handleMarketSelection = (marketId) => {
    setSelectedMarkets((prevSelected) =>
      prevSelected.includes(marketId)
        ? prevSelected.filter((id) => id !== marketId) // Deselect
        : [...prevSelected, marketId] // Select
    );
  };

  // Handle Assign Button Click
  const handleAssignMarkets = async () => {
    const payload = {
      vendorId: vendor._id,
      marketIds: selectedMarkets,
    };
    console.warn("Payload", payload);

    setSubmitting(true);
    try {
      await assignMarketsToVendor(payload);
      console.warn("Markets assigned successfully");
      handleClose(); // Close modal on success
    } catch (err) {
      console.error("Error assigning markets:", err);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchMarketList = async () => {
      try {
        const response = await getAllMarket();
        console.warn("All market data", response);
        const allMarkets = response.data.data;

        setMarketList(allMarkets);

        // Pre-select the vendor's markets
        if (vendor?.marketIds) {
          const vendorMarketIds = vendor.marketIds.map((m) => m._id);
          setSelectedMarkets(vendorMarketIds);
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (show) {
      fetchMarketList();
    }
  }, [show, vendor]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title className="fw-semibold text-primary">
          Assign Markets for {vendor?.businessName}
        </Modal.Title>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        {/* <h5 className="mb-3 text-secondary">Available Markets</h5> */}

        {loading ? (
          <p className="text-center text-muted">Loading markets...</p>
        ) : error ? (
          <p className="text-center text-danger">Error: {error}</p>
        ) : marketList.length > 0 ? (
          <Container fluid>
            <Table bordered hover className="align-middle">
              <thead className="table-light">
                <tr>
                  <th style={{ width: "60%" }}>Market Name</th>
                  <th className="text-center" style={{ width: "20%" }}>
                    Select
                  </th>
                </tr>
              </thead>
              <tbody>
                {marketList.map((market) => (
                  <tr key={market._id}>
                    <td className="fw-semibold">{market.name}</td>
                    <td className="text-center">
                      <Form.Check
                        type="checkbox"
                        checked={selectedMarkets.includes(market._id)}
                        onChange={() => handleMarketSelection(market._id)}
                        className="form-check-lg"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        ) : (
          <p className="text-muted text-center">No markets available</p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleAssignMarkets}
          disabled={selectedMarkets.length === 0 || submitting}
        >
          {submitting ? "Assigning..." : "Assign Selected Markets"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MarketAssignModal;
