import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import DataTable from "react-data-table-component";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import "./masterlocation.css";

const addValidationSchema = Yup.object({
  address: Yup.string().required("Address is required."),
  mapImage: Yup.mixed()
    .nullable()
    .test("required", "Map Image is required.", (value) => value !== null) // Custom required message
    .test(
      "fileFormat",
      "Unsupported File Format! Please upload a .jpg, .jpeg, .png, or .gif image.",
      (value) => {
        if (!value) return false; // Ensures field isn't empty
        const supportedFormats = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
        return supportedFormats.includes(value.type);
      }
    ),
});



const MasterLocation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null); // For editing
  const [locationToDelete, setLocationToDelete] = useState(null); // For delete
  const [imageModalOpen, setImageModalOpen] = useState(false); // For displaying image modal
  const [selectedImage, setSelectedImage] = useState(null); // For storing selected image
  const { userData } = useContext(Context);
  const {
    postMapMasterLocation,
    getMasteLocationList,
    updateMapMasterLocation,
    deleteMapMasterLocation
  } = useApiAxios();
  const staticImagePath = process.env.REACT_APP_IMG_URL;

  // Fetch master locations when the component is mounted
  const fetchLocations = async () => {
    try {
      const response = await getMasteLocationList();
      if (response.data?.data) {
        setLocations(response.data.data);
      } else {
        alert("No locations found");
      }
    } catch (error) {
      alert("Failed to fetch locations");
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  // Handle modal toggle (open/close)
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentLocation(null); // Reset current location when closing modal
  };

  // Open modal in edit mode with selected location
  const handleEditLocation = (location) => {
    setCurrentLocation(location); // Set current location to edit
    setIsModalOpen(true); // Open modal
  };

  // Handle the delete confirmation modal toggle
  const handleDeleteModalToggle = (location) => {
    setLocationToDelete(location); // Set location to delete
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // Handle image click to open modal
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image for preview
    setImageModalOpen(true); // Open image modal
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Immediately check if form is already submitting
    if (values.isSubmitting) return;
  
    try {
      const formData = new FormData();
      formData.append("address", values.address);
  
      if (values.mapImage) {
        formData.append("mapImage", values.mapImage);
      }
  
      // Formik's setSubmitting is already set to true when submission starts
      if (currentLocation) {
        await updateMapMasterLocation(currentLocation._id, formData);
      } else {
        formData.append("marketOwnerId", userData._id);
        await postMapMasterLocation(formData);
      }
  
      fetchLocations();
      setIsModalOpen(false);
      resetForm();
    } catch (error) {
      alert("Failed to save location.");
    } finally {
      setSubmitting(false); // Ensure submitting is false after completion
    }
  };
  
  

  // Handle delete location
  const handleDeleteLocation = async () => {
    try {
      if (locationToDelete) {
        await deleteMapMasterLocation(locationToDelete._id); // Send location ID to delete API
        fetchLocations(); // Fetch updated list of locations after deletion
        setIsDeleteModalOpen(false); // Close delete confirmation modal
      }
    } catch (error) {
      alert("Failed to delete location");
    }
  };

  // Table columns definition
  const columns = [
    { name: "Address", selector: (row) => row.address, sortable: true },
    {
      name: "Map Image",
      selector: (row) =>
        row.mapImage ? (
          <img
            src={`${staticImagePath}/${row.mapImage}`}
            alt="Map"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              objectFit: "cover",
              cursor: "pointer"
            }}
            onClick={() => handleImageClick(row.mapImage)} // Open image in modal
          />
        ) : (
          "No Image"
        ),
      sortable: false
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="button-group">
          <Button
            variant="warning"
            size="sm"
            onClick={() => handleEditLocation(row)}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            size="sm"
            className="ml-2"
            onClick={() => handleDeleteModalToggle(row)}
          >
            Delete
          </Button>
        </div>
      ),
      ignoreRowClick: true
    }
  ];

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Master Location</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/master-location">Master Location</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Create Location Button */}
      <div className="page-inner mt--5">
        <Button variant="primary" onClick={handleModalToggle}>
          Create Location
        </Button>
      </div>

      {/* Modal for adding/editing location */}
      <Modal show={isModalOpen} onHide={handleModalToggle} size="lg">
        <Modal.Header>
          <Modal.Title>
            {currentLocation ? "Edit Location" : "Create Location"}
          </Modal.Title>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            style={{ fontSize: "30px" }}
            aria-label="Close"
            onClick={handleModalToggle}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              address: currentLocation ? currentLocation.address : "",
              mapImage: null // Default to null when editing
            }}
            validationSchema={currentLocation ? null : addValidationSchema} // Apply validation schema only in "Add" mode
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting 
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.address && !!errors.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="mapImage">
                  <Form.Label>Map Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      setFieldValue("mapImage", e.target.files[0])
                    }
                    isInvalid={touched.mapImage && !!errors.mapImage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mapImage}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Preview uploaded image */}
                {values.mapImage && (
                  <div className="mt-3">
                    <h5>Uploaded Map Image:</h5>
                    <img
                      src={URL.createObjectURL(values.mapImage)} // Temporary URL for the selected image
                      alt="Uploaded Map"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "500px",
                        objectFit: "contain"
                      }}
                    />
                  </div>
                )}

                {/* Display existing image if editing */}
                {currentLocation &&
                  !values.mapImage &&
                  currentLocation.mapImage && (
                    <div className="mt-3">
                      <h5>Current Map Image:</h5>
                      <img
                        src={`${staticImagePath}/${currentLocation.mapImage}`}
                        alt="Current Map"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "300px",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  )}

                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={handleModalToggle}
                    className="mr-2"
                  >
                    Close
                  </Button>
                  <Button
    variant="primary"
    type="submit"
    disabled={!values.address || !!errors.address || isSubmitting} // Added isSubmitting check
>
    {isSubmitting ? "Saving..." : "Save Location"}  {/* Optional: change text */}
</Button>

                 

                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Image Preview Modal */}
      <Modal
        show={imageModalOpen}
        onHide={() => setImageModalOpen(false)}
        size="xl" // Set modal size to 'xl' to accommodate large image size
      >
        <Modal.Header>
          <Modal.Title>Image Preview</Modal.Title>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            style={{ fontSize: "30px" }}
            aria-label="Close"
            onClick={() => setImageModalOpen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={`${staticImagePath}/${selectedImage}`}
              alt="Selected Map"
              style={{
                width: "80%", // Image should occupy 80% of the screen width
                height: "auto", // Maintain aspect ratio
                display: "block",
                margin: "0 auto"
              }}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={isDeleteModalOpen} onHide={() => setIsDeleteModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Confirm Deletion</Modal.Title>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            style={{ fontSize: "30px" }}
            aria-label="Close"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this location?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleDeleteLocation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Data Table to display locations */}
      <div className="table-responsive mt-4">
        {locations.length > 0 ? (
          <DataTable
            columns={columns}
            data={locations}
            pagination
            searchable
            highlightOnHover
            striped
            responsive
            customStyles={{
              headCells: {
                style: {
                  backgroundColor: "#f1f1f1",
                  fontWeight: "bold",
                  fontSize: "1rem"
                }
              },
              cells: { style: { padding: "15px" } }
            }}
          />
        ) : (
          <p>No locations available to display.</p>
        )}
      </div>
    </div>
  );
};

export default MasterLocation;
