import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  Card,
  Container,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import useApiAxios from "../../api/useApiAxios";
import { format } from "date-fns";

const ViewVendorDetail = () => {
  const location = useLocation();
  const { vendor } = location.state || {};
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
  const { approveVendorDate, rejectVendorDate, VendorWaitList } = useApiAxios(); // Ensure these methods are defined in your useApiAxios hook

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false); // Modal for approval confirmation

  const [showRejectModal, setShowRejectModal] = useState(false); // Modal for rejection confirmation
  const [showWaitListModal, setShowWaitListModal] = useState(false); // Modal for approval confirmation

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  if (!vendor || !vendor.vendor) {
    return <div>No vendor data available.</div>;
  }

  const handleApprove = async (vendorId) => {
    const data = { approvalStatus: "approved" };
    try {
      setLoading(true);
      const response = await approveVendorDate(vendorId, data);
      setSuccess("Vendor approved successfully.");
      setError(null);
      setShowApprovalModal(false); // Close modal
    } catch (error) {
      setError("Error approving vendor.");
      console.error("Error approving vendor:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (vendorId) => {
    const data = { approvalStatus: "rejected" };
    try {
      setLoading(true);
      const response = await rejectVendorDate(vendorId, data);
      setSuccess("Vendor rejected successfully.");
      setError(null);
      setShowRejectModal(false); // Close modal
    } catch (error) {
      setError("Error rejecting vendor.");
      console.error("Error rejecting vendor:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleWaitlist = async (dateId) => {
    try {
      setLoading(true);
      const response = await VendorWaitList(dateId);
      if (!response.error) {
        setError(null);
      } else {
        setError("Failed to reject vendor");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const isValidDate = (date) => {
    return !isNaN(new Date(date).getTime());
  };

  const formatDate = (date) => {
    if (!isValidDate(date)) {
      return "N/A";
    }
    return format(new Date(date), "dd/MM/yyyy");
  };

  return (
    <>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Vendor Details</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/appliedvendors">Applied Vendors</Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">Vendor Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <Card className="">
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Card.Title>{vendor?.vendor?.businessName}</Card.Title>
                        <Card.Text>
                          <strong>Contact Person:</strong>{" "}
                          {vendor?.vendor?.contactPerson}
                        </Card.Text>
                        <Card.Text>
                          <strong>Contact Number:</strong>{" "}
                          {vendor?.vendor?.contactNumber}
                        </Card.Text>
                        <Card.Text>
                          <strong>Email:</strong> {vendor?.vendor?.email}
                        </Card.Text>
                        <Card.Text>
                          <strong>Role:</strong> {vendor?.vendor?.role}
                        </Card.Text>
                        <Card.Text>
                          <strong>Sub Category:</strong>{" "}
                          {vendor?.vendor?.category[0]?.name}
                        </Card.Text>

                        <Card.Text>
                          <strong>Instagram:</strong>
                          {vendor?.vendor?.instagram &&
                          vendor?.vendor?.instagram !== "N/A" ? (
                            <a
                              href={vendor?.vendor?.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vendor?.vendor?.instagram}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Card.Text>

                        <Card.Text>
                          <strong>Facebook:</strong>
                          {vendor?.vendor?.facebook &&
                          vendor?.vendor?.facebook !== "N/A" ? (
                            <a
                              href={vendor?.vendor?.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vendor?.vendor?.facebook}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Card.Text>

                        <Card.Text>
                          <strong>TikTok:</strong>
                          {vendor?.vendor?.tikTok &&
                          vendor?.vendor?.tikTok !== "N/A" ? (
                            <a
                              href={vendor?.vendor?.tikTok}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vendor?.vendor?.tikTok}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Card.Text>

                        <Card.Text>
                          <strong>Twitter:</strong>
                          {vendor?.vendor?.twitter &&
                          vendor?.vendor?.twitter !== "N/A" ? (
                            <a
                              href={vendor?.vendor?.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vendor?.vendor?.twitter}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Card.Text>

                        <Card.Text>
                          <strong>Liquor License Expiry Date:</strong>{" "}
                          {formatDate(vendor?.vendor?.liquorLicenseExpiryDate)}
                        </Card.Text>
                        <Card.Text>
                          <strong>Expire License Date:</strong>{" "}
                          {formatDate(vendor?.vendor?.expireLicenseDate)}
                        </Card.Text>
                        <Card.Text>
                          <strong>Insurance Expiry Date:</strong>{" "}
                          {formatDate(vendor?.vendor?.insuranceExpiryDate)}
                        </Card.Text>
                        <Card.Text>
                          <strong>Other License Expiry Date:</strong>{" "}
                          {formatDate(vendor?.vendor?.otherLicenseExpiryDate)}
                        </Card.Text>
                      </Col>
                      <Col md={6}>
                        <Card.Text>
                          <strong>Created At:</strong>{" "}
                          {format(
                            new Date(vendor?.vendor?.createdAt),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </Card.Text>
                        <Card.Text>
                          <strong>Updated At:</strong>{" "}
                          {format(
                            new Date(vendor?.vendor?.updatedAt),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                        </Card.Text>
                        <Card.Text>
                          <strong>Business Description:</strong>{" "}
                          {vendor?.vendor?.businessDescription}
                        </Card.Text>
                        <Card.Text>
                          <strong>Add Product Message:</strong>{" "}
                          {vendor?.vendor?.addProductMessage}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Row className="mb-4">
                  <Col md={6}>
                    <Button
                      variant="success"
                      onClick={() => setShowApprovalModal(true)}
                      disabled={loading}
                      style={{ marginRight: "10px" }} // Add margin-right for spacing
                    >
                      {loading ? "Approving..." : "Approve"}
                    </Button>

                    <Button
                      variant="warning"
                      onClick={() => setShowWaitListModal(true)}
                      disabled={vendor?.waitList}
                      style={{ marginRight: "10px" }}
                    >
                      {vendor?.waitList ? "On Waitlist" : "WaitList"}
                    </Button>

                    <Button
                      variant="danger"
                      onClick={() => setShowRejectModal(true)}
                      disabled={loading}
                    >
                      {loading ? "Rejecting..." : "Reject"}
                    </Button>
                  </Col>
                </Row>

                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}

                <Modal
                  show={showApprovalModal}
                  onHide={() => setShowApprovalModal(false)}
                >
                  <Modal.Header >
                    <Modal.Title>Confirm Approval</Modal.Title>
                    <button type="button" className="close" onClick={() => setShowApprovalModal(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to approve this vendor?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowApprovalModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => handleApprove(vendor.vendor._id)}
                      disabled={loading}
                    >
                      {loading ? "Approving..." : "Approve"}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showRejectModal}
                  onHide={() => setShowRejectModal(false)}
                >
                  <Modal.Header >
                    <Modal.Title>Confirm Rejection</Modal.Title>
                    <button type="button" className="close" onClick={() => setShowRejectModal(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to reject this vendor?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowRejectModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleReject(vendor.vendor._id)}
                      disabled={loading}
                    >
                      {loading ? "Rejecting..." : "Reject"}
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={showWaitListModal}
                  onHide={() => setShowWaitListModal(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Confirm Waitlist</Modal.Title>
                    <button type="button" className="close" onClick={() => setShowWaitListModal(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to Waitlist this vendor?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowWaitListModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleWaitlist(vendor.vendor._id)}
                      disabled={loading}
                    >
                      {loading ? "Waitlisting..." : "WaitList"}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <h2 className="text-center mb-4">Images</h2>

                <Row>
                  {[
                    "productImage1",
                    "productImage2",
                    "productImage3",
                    "publicLiabilityInsurance",
                    "foodRegistrationImage",
                    "liquorLicenseImage",
                    "otherLicenseImage"
                  ]
                    .filter((imageKey) => vendor.vendor[imageKey]) // Filter out keys with no image
                    .map((imageKey, idx) => (
                      <Col md={4} key={idx} className="mb-4">
                        <Card className="image-card">
                          <Card.Img
                            variant="top"
                            src={`${IMAGEENDPOINT}/${vendor.vendor[imageKey]}`}
                            onClick={() =>
                              handleImageClick(
                                `${IMAGEENDPOINT}/${vendor.vendor[imageKey]}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                          <Card.Body>
                            <Card.Text>
                              <strong>
                                {imageKey
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, (str) => str.toUpperCase())
                                  .replace(" Image", "")}
                              </strong>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>

                <Modal
                  show={showImageModal}
                  onHide={() => setShowImageModal(false)}
                  size="lg"
                >
                  <Modal.Header>
                    <Modal.Title>Image Preview</Modal.Title>
                    <button type="button" className="close" onClick={() => setShowImageModal(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowImageModal(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <style>
                  {`
          .image-card {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 100%;
            height: auto;
          }

          .image-card .card-img-top {
            height: 400px; 
            width: 100%;
            object-fit: cover;
            cursor: pointer;
          }

          .image-card .card-body {
            padding: 1rem;
          }

          .text-center {
            text-align: center;
          }

          .mb-4 {
            margin-bottom: 1.5rem;
          }

          .my-5 {
            margin: 3rem 0;
          }
        `}
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewVendorDetail;
