import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate";
import { Modal, Button, Form } from "react-bootstrap";

const VendorEventFestival = () => {
  const { userData } = useContext(Context);
  const vendor = userData.vendor;
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
  const {
    getAllEventFestivalApiVendor,
    applyEventApi,
    getVendorDateInvoicePaymentViaMail,
    updateVendorPaymentSubscriptionApi,
    postVendorManualTransaction,
    getMarketOwnerBankDetail,
    getVendorDateInvoicePaymentUrl
  } = useApiAxios();
  const [tableData, setTableData] = useState([]);

  // Modal State
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [useWallet, setUseWallet] = useState(false);
  const [manualTransactionId, setManualTransactionId] = useState("");
  const [transactionIdError, setTransactionIdError] = useState("");
  const [bankDetails, setBankDetails] = useState(null); // Store bank details
  const [errorMessage, setErrorMessage] = useState("");

  // Pagination State
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    document.title = "Market Details - Eventease360";

    const fetchData = async () => {
      try {
        const ownerId = userData?.vendor?.owner;
        const response = await getAllEventFestivalApiVendor(ownerId);
        console.warn("Fetched Event Data", response);
        setTableData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching event festival data:", error);
      }
    };

    fetchData();
  }, [userData]);

  const walletAmmount = userData?.vendor?.walletAmount;
  console.warn("wallet amount", walletAmmount);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const applyEvent = async (eventId) => {
    try {
      const vendorId = vendor?._id;
      const payload = { eventId, vendorId };
      const response = await applyEventApi(payload);

      if (!response.error) {
        console.log("Apply event response:", response.data);
        const ownerId = userData?.vendor?.owner;
        const updatedResponse = await getAllEventFestivalApiVendor(ownerId);
        setTableData(updatedResponse?.data?.data);
      } else {
        console.error("Error applying for event:", response.message);
      }
    } catch (error) {
      console.error("Unexpected error applying for event:", error);
    }
  };

  const handlePayment = async (eventId, amount) => {
    const selectedEvent = tableData.find((event) => event._id === eventId);
    console.warn("invoiceid", selectedEvent);
    setSelectedPayment(selectedEvent);
    setModalVisible(true);

    try {
      const ownerId = userData?.vendor?.owner;
      const response = await getMarketOwnerBankDetail(ownerId);
      setBankDetails(response?.data?.data || null);
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const handleWalletChange = (e) => {
    setUseWallet(e.target.checked);
  };

  const closeModal = () => {
    setModalVisible(false);
    setUseWallet(false);
    setManualTransactionId("");
    setTransactionIdError("");
    setBankDetails(null); // Clear bank details on close
  };

  // const makePaymentHandler = (paymentId, walletAmount) => {
  //   console.log("Proceeding with payment:", paymentId, walletAmount);
  //   // Here, implement payment logic
  //   closeModal();
  // };

  // Handle Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentData = tableData?.slice(offset, offset + itemsPerPage);

  const handleManualPayment = async (
    ownerId,
    transactionId,
    accountNumber,
    amount,
    paymentId
  ) => {
    if (!transactionId) {
      setTransactionIdError("Transaction ID is required.");
      return;
    }

    try {
      const payload = {
        marketOwnerId: ownerId,
        transactionId,
        accountId: accountNumber,
        amount,
        vendorInvoiceId: paymentId
      };

      const response = await postVendorManualTransaction(payload);

      if (response?.data?.success) {
        console.log("Manual transaction successful", response.data);
        closeModal();
      } else {
        console.error("Error processing transaction:", response?.data?.message);
      }
    } catch (error) {
      console.error("Unexpected error during manual transaction:", error);
    }
  };

  const makePaymentHandler = async (id, walletAmount) => {
    try {
      if (!selectedPayment) {
        setErrorMessage("No event selected for payment.");
        return;
      }

      const totalAmount =
        selectedPayment?.price * (selectedPayment?.vendorDates?.length || 1);

      let finalWalletAmount = Math.min(walletAmount, totalAmount);

      const { data, error } = await getVendorDateInvoicePaymentUrl({
        vendorInvoiceId: id,
        walletAmount: finalWalletAmount
      });

      if (error || !data?.data?.sessionUrl) {
        setErrorMessage("Failed to initiate payment. Please try again.");
        return;
      }

      window.location.href = data.data.sessionUrl;
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div>
      {/* Event Table and other components */}
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Events & Festival</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendorOwndeEvents">Events & Festival</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Data Table */}
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Event</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Address</th>
              <th>Type</th>
              <th>Payment Type</th>
              <th>Application Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((event, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      src={`${IMAGEENDPOINT}/${event.image}`}
                      alt="Event"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "5px",
                        marginRight: "10px"
                      }}
                    />
                    <span>{event.eventName || "N/A"}</span>
                  </div>
                </td>
                <td>{formatDate(event.startDate)}</td>
                <td>{formatDate(event.endDate)}</td>
                <td>{event?.address || "N/A"}</td>


                <td>
                  <span
                    className={`badge ${
                      event.type === "Festival" ? "badge-success" : "badge-info"
                    }`}
                  >
                    {event.type || "N/A"}
                  </span>
                </td>
                <td>
                  <span
                    className={`badge ${
                      event.paymentType === "Free"
                        ? "badge-primary"
                        : "badge-warning"
                    }`}
                  >
                    {event.paymentType || "N/A"}
                  </span>
                </td>
                <td>
                  {/* Show Application Status */}
                  {event.applications?.[0]?.approvalStatus ? (
                    <span
                      className={`badge ${
                        event.applications[0].approvalStatus === "approved"
                          ? "badge-success"
                          : event.applications[0].approvalStatus === "pending"
                          ? "badge-warning"
                          : "badge-danger"
                      }`}
                    >
                      {event.applications[0].approvalStatus}
                    </span>
                  ) : (
                    "Not Applied"
                  )}
                </td>
                <td>
                  {/* Action Buttons */}
                  <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={() => applyEvent(event._id)}
                    disabled={
                      event.applications?.[0]?.approvalStatus === "approved" ||
                      event.applications?.[0]?.approvalStatus === "pending"
                    }
                  >
                    Apply
                  </button>

                  {event.paymentType === "paid" &&
                    event.applications?.[0]?.approvalStatus === "approved" && (
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => handlePayment(event._id, event.amount)}
                      >
                        Pay
                      </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Section */}
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={Math.ceil(tableData?.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />

      {/* Modal for Payment */}
      <Modal show={modalVisible} onHide={closeModal} size="lg" scrollable>
        <Modal.Header>
          <Modal.Title>Payment Details</Modal.Title>
          <button
            type="button"
            className="close"
            style={{ fontSize: "30px" }}
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <table className="table mb-0">
                <tbody>
                  {/* Event Details */}
                  <tr>
                    <th>Event Name:</th>
                    <td>{selectedPayment?.eventName || "N/A"}</td>
                  </tr>
                  <tr>
                    <th>Total Amount to be Paid:</th>
                    <td className="font-weight-bold h2">
                      ${selectedPayment?.amount || 0}
                    </td>
                  </tr>

                  {/* Wallet Amount */}
                  {selectedPayment?.vendorId?.walletAmount > 0 && (
                    <tr>
                      <th>Wallet Amount:</th>
                      <td>
                        <input
                          type="checkbox"
                          id="useWallet"
                          checked={useWallet}
                          onChange={(e) => setUseWallet(e.target.checked)}
                          className="mr-2"
                        />
                        ${selectedPayment?.vendorId?.walletAmount}
                      </td>
                    </tr>
                  )}

                  {/* Bank Details */}
                  {bankDetails && (
                    <>
                      <tr>
                        <td colSpan={2} className="border rounded-3 bg-light">
                          {bankDetails && (
                            <div className="row">
                              <div className="col-sm-6 mb-3">
                                <Form.Label className="font-weight-bold">
                                  Account Number:
                                </Form.Label>
                                <p className="mb-0 text-primary">
                                  {bankDetails.accountNumber}
                                </p>
                              </div>
                              <div className="col-sm-6 mb-3">
                                <Form.Label className="font-weight-bold">
                                  BSB Code:
                                </Form.Label>
                                <p className="mb-0 text-primary">
                                  {bankDetails.ifscCode}
                                </p>
                              </div>
                              <div className="col-sm-6 mb-3">
                                <Form.Label className="font-weight-bold">
                                  Bank Name:
                                </Form.Label>
                                <p className="mb-0 text-primary">
                                  {bankDetails.bankName}
                                </p>
                              </div>
                              <div className="col-sm-6 mb-3">
                                <Form.Label className="font-weight-bold">
                                  Account Holder Name:
                                </Form.Label>
                                <p className="mb-0 text-primary">
                                  {bankDetails.customerName}
                                </p>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  )}

                  {/* Transaction ID Input */}
                  <tr>
                    <th>Transaction ID:</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={manualTransactionId}
                        onChange={(e) => setManualTransactionId(e.target.value)}
                        placeholder="Enter transaction ID"
                        isInvalid={!!transactionIdError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {transactionIdError}
                      </Form.Control.Feedback>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              handleManualPayment(
                userData?.vendor?.owner,
                manualTransactionId,
                bankDetails?.accountNumber,
                selectedPayment?.amount,
                selectedPayment?._id
              )
            }
          >
            Submit Payment Via Bank
          </Button>

          <Button
            variant="primary"
            onClick={() =>
              makePaymentHandler(
                selectedPayment?.invoices[0]?._id,
                useWallet ? 0 : 0
              )
            }
          >
            Pay Online
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorEventFestival;
