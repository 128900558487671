// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import useApiAxios from "../../api/useApiAxios";

// const ManualTransaction = () => {
//   const { updateVendorTransactionStatus, getMarketOwnerPaymentList } = useApiAxios();

//   const [transactions, setTransactions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Pagination state
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 3; // Number of transactions per page

//   // Modal state for rejecting with a reason
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [rejectionReason, setRejectionReason] = useState('');
//   const [selectedTransactionId, setSelectedTransactionId] = useState(null);

//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await getMarketOwnerPaymentList();
//         console.warn("PPPPPP----PPPPPPPP", response.data.data.transactions)
//         if (response && response.data.data) {
//           // Map the API response to match your table's data structure
//           const mappedTransactions = response?.data?.data?.transactions.map((transaction) => ({
//             transactionId: transaction.transactionId,  // Display the transactionId
//             vendorEmail: transaction.vendorId.email, // Access vendor's email
//             date: new Date(transaction.createdAt).toLocaleDateString(), // Format the date
//             status: transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1), // Capitalize first letter of status
//             amount: transaction.amount, // Amount
//             id: transaction._id, // Using _id for API actions
//             vendorInvoiceId: transaction.vendorInvoiceId
//           }));
//           console.warn("PPPPPPPPPPPPPPPPPPPP", mappedTransactions)

//           setTransactions(mappedTransactions);
//         }
//       } catch (error) {
//         setError("Error fetching transactions");
//         console.error("Error fetching transactions:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTransactions();
//   }, []);

//   // Handle Accept action
//   const handleAccept = async (_id, vendorInvoiceId) => {
//     try {
//       const response = await updateVendorTransactionStatus({
//         id: _id,
//         status: "approved",
//         vendorInvoiceId: vendorInvoiceId
//       });
//       if (response) {
//         setTransactions((prevTransactions) =>
//           prevTransactions.map((transaction) =>
//             transaction.id === _id
//               ? { ...transaction, status: "accepted" }
//               : transaction
//           )
//         );
//         console.warn(`Transaction ${_id} Accepted`);
//       }
//     } catch (error) {
//       console.error("Error accepting transaction", error);
//     }
//   };

//   // Handle Reject action (trigger modal)
//   const handleReject = (_id) => {
//     setSelectedTransactionId(_id);
//     setIsModalOpen(true);
//   };

//   // Submit rejection with reason
//   const handleRejectSubmit = async () => {
//     if (!rejectionReason) {
//       alert("Please provide a reason for rejection");
//       return;
//     }

//     try {
//       const response = await updateVendorTransactionStatus({
//         id: selectedTransactionId,
//         status: "rejected",
//         reason: rejectionReason // Send rejection reason
//       });
//       if (response) {
//         setTransactions((prevTransactions) =>
//           prevTransactions.map((transaction) =>
//             transaction.id === selectedTransactionId
//               ? { ...transaction, status: "rejected" }
//               : transaction
//           )
//         );
//         console.warn(`Transaction ${selectedTransactionId} Rejected`);
//         setIsModalOpen(false); // Close the modal
//         setRejectionReason(''); // Reset rejection reason
//       }
//     } catch (error) {
//       console.error("Error rejecting transaction", error);
//     }
//   };

//   // Calculate total pages
//   const totalPages = Math.ceil(transactions.length / itemsPerPage);

//   // Get current transactions to display based on the current page
//   const indexOfLastTransaction = currentPage * itemsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
//   const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

//   // Pagination buttons
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div>
//       <div className="panel-header">
//         <div className="page-inner pb-5">
//           <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
//             <div>
//               <h2 className="pb-2 fw-bold">Manual Transaction Logs</h2>
//               <ul className="breadcrumbs">
//                 <li className="nav-home">
//                   <Link to="/">
//                     <i className="flaticon-home" />
//                   </Link>
//                 </li>
//                 <li className="separator">
//                   <i className="flaticon-right-arrow" />
//                 </li>
//                 <li className="nav-item">
//                   <Link to="/manualTransaction">Manual Transaction</Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Table to display the transaction logs */}
//       <div className="page-inner mt--5">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="card">
//               <div className="card-body">
//                 {loading ? (
//                   <p>Loading transactions...</p>
//                 ) : error ? (
//                   <p>{error}</p>
//                 ) : (
//                   <table className="table table-hover">
//                     <thead>
//                       <tr>
//                         <th>Vendor Email</th>
//                         <th>Date</th>
//                         <th>Status</th>
//                         <th>Transaction ID</th>
//                         <th>Amount</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {currentTransactions.length > 0 ? (
//                         currentTransactions.map((transaction, index) => (
//                           <tr key={index}>
//                             <td>{transaction.vendorEmail}</td>
//                             <td>{transaction.date}</td>
//                             <td>{transaction.status}</td>
//                             <td>{transaction.transactionId}</td>
//                             <td>{transaction.amount}</td>
//                             <td>
//                               <button
//                                 onClick={() => handleAccept(transaction.id, transaction.vendorInvoiceId)}
//                                 className="btn btn-success btn-sm m-2"
//                               >
//                                 Accept
//                               </button>
//                               <button
//                                 onClick={() => handleReject(transaction.id)}
//                                 className="btn btn-danger btn-sm"
//                               >
//                                 Not Accept
//                               </button>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan="6">No transactions found.</td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Pagination Controls */}
//         <div className="row">
//           <div className="col-md-12">
//             <div className="d-flex justify-content-center">
//               <nav aria-label="Page navigation">
//                 <ul className="pagination">
//                   <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
//                     <button className="page-link" onClick={() => paginate(currentPage - 1)}>
//                       Previous
//                     </button>
//                   </li>
//                   {[...Array(totalPages)].map((_, index) => (
//                     <li
//                       key={index}
//                       className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
//                     >
//                       <button className="page-link" onClick={() => paginate(index + 1)}>
//                         {index + 1}
//                       </button>
//                     </li>
//                   ))}
//                   <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
//                     <button className="page-link" onClick={() => paginate(currentPage + 1)}>
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal for rejection reason */}
//       {isModalOpen && (
//         <div className="modal" style={{ display: "block" }}>
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Rejection Reason</h5>
//                 <button
//                   type="button"
//                   className="close"
//                   onClick={() => setIsModalOpen(false)}
//                 >
//                   <span>&times;</span>
//                 </button>
//               </div>
//               <div className="modal-body">
//                 <textarea
//                   className="form-control"
//                   rows="4"
//                   value={rejectionReason}
//                   onChange={(e) => setRejectionReason(e.target.value)}
//                   placeholder="Enter the reason for rejection"
//                 ></textarea>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"
//                   onClick={() => setIsModalOpen(false)}
//                 >
//                   Close
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={handleRejectSubmit}
//                 >
//                   Reject
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ManualTransaction;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";

const ManualTransaction = () => {
  const { updateVendorTransactionStatus, getMarketOwnerPaymentList } = useApiAxios();

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Adjust if needed

  // Modal state for rejecting with a reason
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await getMarketOwnerPaymentList();
        console.warn("Full API Response:", response);

        if (response?.data?.data?.transactions?.length > 0) {
          const mappedTransactions = response.data.data.transactions.map((transaction) => ({
            transactionId: transaction.transactionId || "N/A",
            vendorEmail: transaction.vendorId?.email || "N/A", // Handle null vendorId
            date: transaction.createdAt ? new Date(transaction.createdAt).toLocaleString() : "N/A",
            status: transaction.status ? transaction.status.replace(/([A-Z])/g, ' $1').trim() : "N/A", // Better status formatting
            amount: transaction.amount ? `${parseFloat(transaction.amount).toFixed(2)}` : "$0.00", // Format amount
            id: transaction._id, 
            vendorInvoiceId: transaction.vendorInvoiceId || "N/A",
          }));

          console.warn("Mapped Transactions:", mappedTransactions);
          setTransactions(mappedTransactions);
        } else {
          setError("No transactions found.");
        }
      } catch (error) {
        setError("Error fetching transactions.");
        console.error("API Fetch Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  // Handle Accept action
  const handleAccept = async (_id, vendorInvoiceId) => {
    try {
      const response = await updateVendorTransactionStatus({
        id: _id,
        status: "approved",
        vendorInvoiceId: vendorInvoiceId,
      });
      if (response) {
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction.id === _id ? { ...transaction, status: "Accepted" } : transaction
          )
        );
        console.warn(`Transaction ${_id} Accepted`);
      }
    } catch (error) {
      console.error("Error accepting transaction", error);
    }
  };

  // Handle Reject action (trigger modal)
  const handleReject = (_id) => {
    setSelectedTransactionId(_id);
    setIsModalOpen(true);
  };

  // Submit rejection with reason
  const handleRejectSubmit = async () => {
    if (!rejectionReason) {
      alert("Please provide a reason for rejection");
      return;
    }

    try {
      const response = await updateVendorTransactionStatus({
        id: selectedTransactionId,
        status: "rejected",
        reason: rejectionReason,
      });

      if (response) {
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction.id === selectedTransactionId ? { ...transaction, status: "Rejected" } : transaction
          )
        );
        console.warn(`Transaction ${selectedTransactionId} Rejected`);
        setIsModalOpen(false);
        setRejectionReason('');
      }
    } catch (error) {
      console.error("Error rejecting transaction", error);
    }
  };

  // Pagination Logic
  const totalPages = Math.ceil(transactions.length / itemsPerPage);
  const currentTransactions = transactions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Manual Transaction Logs</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/manualTransaction">Manual Transaction</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Transaction Table */}
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                {loading ? (
                  <p>Loading transactions...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Vendor Email</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTransactions.length > 0 ? (
                        currentTransactions.map((transaction, index) => (
                          <tr key={index}>
                            <td>{transaction.vendorEmail}</td>
                            <td>{transaction.date}</td>
                            <td>{transaction.status}</td>
                            <td>{transaction.transactionId}</td>
                            <td>${transaction.amount}</td>
                            <td>
                              <button
                                onClick={() => handleAccept(transaction.id, transaction.vendorInvoiceId)}
                                className="btn btn-success btn-sm m-2"
                              >
                                Accept
                              </button>
                              <button
                                onClick={() => handleReject(transaction.id)}
                                className="btn btn-danger btn-sm"
                              >
                                Reject
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No transactions found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <nav aria-label="Page navigation">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                        <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualTransaction;

