

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";

const AppliedVendorsEvents = () => {
  const { userData } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [marketPerPage, setMarketPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    getAppliedVendorEventFestival,
    approveVendorEvent,
    rejectVendorEvent,
    approveVendorEventFestival
  } = useApiAxios();

  const [eventAndFestivalData, setEventAndFestivalData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Stores selected event data

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAppliedVendorEventFestival();
        setEventAndFestivalData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching event festival data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleApproveVendor = async (applicationId) => {
    try {
      const payload = {
        _id: applicationId,
        approvalStatus: "approved"
      };
      await approveVendorEventFestival(payload);
  
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        applications: prevEvent.applications.map((app) =>
          app._id === applicationId
            ? { ...app, approvalStatus: "approved" }
            : app
        )
      }));
    } catch (error) {
      console.error("Error approving vendor", error);
    }
  };
  
  const handleDisapproveVendor = async (applicationId) => {

    try {
      const payload = {
        _id: applicationId,
        approvalStatus: "disapproved"
      };
      await approveVendorEventFestival(payload);
  
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        applications: prevEvent.applications.map((app) =>
          app._id === applicationId
            ? { ...app, approvalStatus: "disapproved" }
            : app
        )
      }));
    } catch (error) {
      console.error("Error disapproving vendor", error);
    }
  };
  
  const handleViewEventDetails = (event) => {
    setSelectedEvent(event);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const offset = currentPage * marketPerPage;
  const paginatedData = eventAndFestivalData.slice(offset, offset + marketPerPage);
  const pageCount = Math.ceil(eventAndFestivalData.length / marketPerPage);

  return (
    <div className="container-fluid">
    <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Applied Events & Festival</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/event-festival"> Applied Events & Festival</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* Events List Column */}
        <div className="col-md-6">
          {loading ? (
            <div className="text-center my-4">Loading...</div>
          ) : (
            <div className="card">
              <div className="card-body">
                <h4 className="fw-bold">Applied Events & Festival</h4>
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Event Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {paginatedData.map((event) => (
                        <tr key={event._id}>
                          <td>{event.eventName || "N/A"}</td>
                          <td>{formatDate(event.startDate)}</td>
                          <td>{formatDate(event.endDate)}</td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleViewEventDetails(event)}
                            >
                              Manage Event/Festival
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => setCurrentPage(selected)}
                    containerClassName={"pagination justify-content-center"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Vendor Details Column */}
        <div className="col-md-6">
          {selectedEvent && (
            <div className="card">
              <div className="card-body">
                <h4 className="fw-bold">Vendors for {selectedEvent.eventName}</h4>

                {selectedEvent?.applications?.length > 0 ? (
                  selectedEvent.applications.map((application) => (
                    <div key={application._id} className="mb-3">
                      <p>
                        Vendor: <strong>{application.vendorId?.businessName || "N/A"}</strong>
                      </p>
                      <p>
                        Status:{" "}
                        <span
                          className={`badge ${
                            application.approvalStatus === "approved"
                              ? "badge-success"
                              : application.approvalStatus === "disapproved"
                              ? "badge-danger"
                              : "badge-warning"
                          }`}
                        >
                          {application.approvalStatus}
                        </span>
                      </p>

                      {application.approvalStatus === "pending" && (
  <>
    <button
      className="btn btn-success btn-sm mr-2"
      onClick={() => handleApproveVendor(application._id)}
    >
      Approve
    </button>
    <button
      className="btn btn-danger btn-sm"
      onClick={() => handleDisapproveVendor(application._id)}
    >
      Disapprove
    </button>
  </>
)}



                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No vendors found for this event.</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppliedVendorsEvents;
