import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";

const Header = ({ setSideBarEnable, showMobileSidebar, setShowMobileSidebar }) => {
	const { logOutApi } = useApiAxios();
	const { userData } = useContext(Context);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const isMobile = window.innerWidth <= 991;

	const handleSidebarToggle = () => {
		if (isMobile) {
			setShowMobileSidebar(!showMobileSidebar);
		} else {
			setSideBarEnable(prev => !prev);
		}
	};

	const logoHeaderStyle = {
		width: isSidebarOpen ? "90px" : "250px",
		transition: "width 0.3s",
	};

	
	
	  const logoImageStyle = {
		width: isSidebarOpen ? "40px" : "180px", // Adjust the width values as needed
		transition: "width 0.3s",
	  };

	const imageEndPoint = process.env.REACT_APP_IMG_URL;

	const vendorId = userData._id; // Extract the vendorId from the context
	const walletAmmount = userData?.vendor?.walletAmount;

	return (
		<div className="main-header">
			{/* Logo Header */}
			<div className="logo-header" style={logoHeaderStyle} data-background-color="light-blue">
				<Link to="/" className="logo">
				<img
            src={
              isSidebarOpen ? "/assets/img/icon.png" : "/assets/img/logo-white.svg"
            }
            alt="navbar brand"
            className="navbar-brand"
            style={logoImageStyle}
          />
				</Link>
				<button
					className="navbar-toggler"
					onClick={handleSidebarToggle}
					type="button"
				>
					<i className="fas fa-bars"></i>
				</button>
				<div className={`nav-toggle ${isSidebarOpen ? "shifted" : ""}`}>
					<button className="btn btn-toggle toggle-sidebar" onClick={handleSidebarToggle}>
						<i className="icon-menu" />
					</button>
				</div>
			</div>
			{/* End Logo Header */}

			{/* Navbar Header */}
			<nav className="navbar navbar-header navbar-expand-lg" data-background-color="light-blue2">
				<div className="container-fluid">
					<ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
						{userData.role === "vendor" && (
							<li className="nav-item d-flex align-items-center">
								<Link to="/transaction-logs" className="d-flex align-items-center text-decoration-none">
									<i className="fas fa-wallet mr-2 text-white" style={{ fontSize: "1.5rem" }}></i>
									<span className="nav-link text-white pr-1" style={{ fontSize: "16px" }}>
										Wallet Balance:
									</span>
									<span className="nav-link fw-bold text-white pl-0" style={{ fontSize: "16px" }}>
										${walletAmmount ? walletAmmount.toFixed(2) : "0.00"}
									</span>
								</Link>
							</li>
						)}

						<li className="nav-item toggle-nav-search hidden-caret">
							<a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
								<i className="fa fa-search" />
							</a>
						</li>

						<li className="nav-item dropdown hidden-caret">
							<a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
								<div className="avatar-sm">
									<img
										src={
											userData.vendor?.profileImage
												? `${imageEndPoint}/${userData.vendor.profileImage}`
												: userData.profileImage
												? `${imageEndPoint}/${userData.profileImage}`
												: "/assets/img/profile.jpg"
										}
										alt="..."
										className="avatar-img rounded-circle"
									/>
								</div>
							</a>
							<ul className="dropdown-menu dropdown-user animated fadeIn">
								<div className="dropdown-user-scroll scrollbar-outer">
									<li>
										<div className="user-box">
											<div className="avatar-lg">
												<img
													src={
														userData.vendor?.profileImage
															? `${imageEndPoint}/${userData.vendor.profileImage}`
															: userData.profileImage
															? `${imageEndPoint}/${userData.profileImage}`
															: "/assets/img/profile.jpg"
													}
													alt="image profile"
													className="avatar-img rounded-circle"
												/>
											</div>
											<div className="u-text">
												<h4>{userData.name}</h4>
												<p className="text-muted">{userData.email}</p>
											</div>
										</div>
									</li>
									<li>
										<div className="dropdown-divider" />
										<Link to={userData.role === "vendor" ? "/" : "/profile"} className="dropdown-item">
											My Profile
										</Link>
										{userData.role === "vendor" && (
											<Link to="/vendorsettings" className="dropdown-item">
												Change Profile Image
											</Link>
										)}
										<div className="dropdown-divider" />
										<Link to="#" className="dropdown-item" onClick={logOutApi}>
											Logout
										</Link>
									</li>
								</div>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
			{/* End Navbar */}
		</div>
	);
};

export default Header;
