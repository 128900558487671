// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// const MarketActionModal = ({ show, handleClose, vendor, onAccept, onDecline, onWaitList }) => {
//   console.warn("market and vendor data", vendor);

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header>
//         <Modal.Title>Manage Markets for {vendor?.businessName}</Modal.Title>
//         <button type="button" className="close" onClick={handleClose}>
//           <span aria-hidden="true">&times;</span>
//         </button>
//       </Modal.Header>
//       <Modal.Body>
//         <h5>Market Names:</h5>
//         {vendor?.marketIds && vendor.marketIds.length > 0 ? (
//           vendor.marketIds.map((market) => {
//             const approval = vendor.approvalStatus.find((status) => status.marketId === market._id);

//             let statusText = "Status Pending"; // Default text

//             if (approval) {
//               switch (approval.status) {
//                 case "waitList":
//                   statusText = "WAITLIST";
//                   break;
//                 case "Approved":
//                   statusText = "APPROVED";
//                   break;
//                 case "disapprove":
//                   statusText = "DECLINED";
//                   break;
//                 default:
//                   statusText = approval.status;
//               }
//             }

//             return (
//               <div key={market._id} className="d-flex justify-content-between align-items-center mb-2">
//                 <span
//                   style={{
//                     display: "inline-block",
//                     backgroundColor: "#007bff",
//                     color: "white",
//                     padding: "5px 10px",
//                     borderRadius: "12px",
//                     fontSize: "14px",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   {market.name}
//                 </span>

//                 <div>
//                   <Button variant="success" onClick={() => onAccept(market)}>
//                     Accept
//                   </Button>
//                   <Button variant="danger" onClick={() => onDecline(market)} className="ml-2">
//                     Decline
//                   </Button>
//                   <Button variant="warning" onClick={() => onWaitList(market)} className="ml-2">
//                     Waitlist
//                   </Button>
//                 </div>

//                 {/* Display the customized status text */}
//                 <span className="ml-2 font-weight-bold">{statusText}</span>
//               </div>
//             );
//           })
//         ) : (
//           <p>No markets available</p>
//         )}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default MarketActionModal;

import React from "react";
import { Modal, Button, Badge, Row, Col } from "react-bootstrap";

const MarketActionModal = ({
  show,
  handleClose,
  vendor,
  onAccept,
  onDecline,
  onWaitList
}) => {
  console.warn("market and vendor data", vendor);

  return (
    <Modal show={show} onHide={handleClose} size={"lg"}>
      <Modal.Header>
        <Modal.Title className="fw-semibold text-primary">
          Manage Markets for {vendor?.businessName}
        </Modal.Title>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* <h5 className="mb-3 text-secondary">Market Assignments</h5> */}
        {vendor?.marketIds && vendor.marketIds.length > 0 ? (
          vendor.marketIds.map((market) => {
            const approval = vendor.approvalStatus.find(
              (status) => status.marketId === market._id
            );

            let statusText = "Pending";
            let statusVariant = "secondary"; // Default status color

            if (approval) {
              switch (approval.status) {
                case "waitList":
                  statusText = "Waitlisted";
                  statusVariant = "warning";
                  break;
                case "Approved":
                  statusText = "Approved";
                  statusVariant = "success";
                  break;
                case "disapprove":
                  statusText = "Declined";
                  statusVariant = "danger";
                  break;
                default:
                  statusText = approval.status;
              }
            }

            return (
              <Row key={market._id} className="align-items-center p-3 border rounded mb-2 shadow-sm">
  <Col md={4} className="fw-semibold text-dark">{market.name}</Col>

  <Col md={2}>
    <Badge bg={statusVariant} className="px-3 py-2 text-uppercase">
      {statusText}
    </Badge>
  </Col>

  <Col md={6} className="d-flex justify-content-end">
    <div className="d-flex gap-3">
      <Button variant="outline-success" size="sm" onClick={() => onAccept(market)}>
        Accept
      </Button>
      <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => onDecline(market)}>
        Decline
      </Button>
      <Button variant="outline-warning" size="sm" onClick={() => onWaitList(market)}>
        Waitlist
      </Button>
    </div>
  </Col>
</Row>


            );
          })
        ) : (
          <p className="text-muted">No markets available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MarketActionModal;
