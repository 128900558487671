import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import SubAdminSidebarData from "./SubAdminSidebar.json";
import VendorSidebarData from "./VendorSideBar.json";
import MarketManager from "./MarketManager.json";
import "./sidebar.css";

const SideBar = ({ isCollapsed, showMobileSidebar, setShowMobileSidebar }) => {
	const { userData } = useContext(Context);
	const sidebarData = userData.role === "vendor" ? VendorSidebarData : userData.role === "manager" ? MarketManager : SubAdminSidebarData;
	const [openSubMenu, setOpenSubMenu] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

	useEffect(() => {
		const handleResize = () => {
			const mobile = window.innerWidth <= 767;
			setIsMobile(mobile);
			if (mobile) {
				setShowMobileSidebar(false);
			}
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [setShowMobileSidebar]);

	const toggleSubMenu = (index) => {
		setOpenSubMenu(openSubMenu === index ? null : index);
	};

	const handleMenuClick = () => {
		if (isMobile) {
			setShowMobileSidebar(false);
		}
	};

	const imageEndPoint = process.env.REACT_APP_IMG_URL; // Adjust according to your setup

	return (
		<div className={`sidebar ${isCollapsed ? "collapsed" : ""} ${isMobile ? (showMobileSidebar ? "show" : "") : ""}`}>
			<div className="sidebar-content">
				<div className="user">
					<div className="avatar-sm float-left mr-2">
						<img
							src={
								userData.vendor?.profileImage
									? `${imageEndPoint}/${userData.vendor.profileImage}`
									: userData.profileImage
									? `${imageEndPoint}/${userData.profileImage}`
									: "/assets/img/profile.jpg"
							}
							alt="Profile"
							className="avatar-img rounded-circle"
						/>
					</div>
					{!isCollapsed && (
						<div className="info">
							<a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
								<span>
									{userData.name || userData.vendor?.businessName}
									<span className="user-level">{userData.email}</span>
								</span>
							</a>
						</div>
					)}
				</div>
				<ul className="nav nav-primary">
					{sidebarData.menuItems.map((menuItem, index) => (
						<li className="nav-item" key={menuItem.id || index}>
							{menuItem.subMenu ? (
								<>
									<a onClick={() => toggleSubMenu(index)} style={{ cursor: "pointer" }}>
										<i className={menuItem.icon} />
										{!isCollapsed && <p>{menuItem.title}</p>}
										<span className="caret" />
									</a>
									<div className={`collapse ${openSubMenu === index ? "show" : ""}`}>
										<ul className="nav nav-collapse">
											{menuItem.subMenu.map((subItem, subIndex) => (
												<li key={subItem.id || subIndex}>
													<Link to={subItem.link} onClick={handleMenuClick}>
														<span className="sub-item">{subItem.title}</span>
													</Link>
												</li>
											))}
										</ul>
									</div>
								</>
							) : (
								<Link to={menuItem.link} onClick={handleMenuClick}>
									<i className={menuItem.icon} />
									{!isCollapsed && <p>{menuItem.title}</p>}
								</Link>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default SideBar;
