import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, handleClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header>
        <Modal.Title>Confirm Deletion</Modal.Title>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this vendor?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
